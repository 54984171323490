.Loader{
    &__Container{
        min-height: calc(100vh - 277.5px);
        display: flex;
        justify-content: center;
        align-items: center;

        @media (min-width: 1200px) {
            min-height: calc(100vh - 541px);

        }
        &--full{
            min-height: 100vh;
        }
    }
}