.GlobalRatings {
    &__Container {

padding:5rem 0;
@media (max-width: 1199px) {
    padding:3.3rem 0 0;
}
        h2 {
            text-align: left;
            padding-left: 0;
            font-family: $font-helvetica-neue;
            font-size: 36px;
            line-height: 44px;
            font-weight: bold;
            color: #000000;
            margin-bottom: 3rem;
            @media (max-width: 1199px) {
               text-align: left;
                vertical-align: middle;
                font-family:  $font-helvetica-neue;
                font-style: normal;
                font-weight: bold;
                font-size: 24px;
                line-height: 29px;
                padding: 24.63px 0 24.63px 32px;
                color: #000000;
                min-height: 78px;
                background: rgba(0, 0, 0, 0.05);
                margin-bottom:56px;
            }
            &.title-brand{
                background: transparent;
                margin-bottom: 2rem;
            }
        }
        .Ratings{
            &__Container{
                .Dot{
                    box-shadow: 2px 2px 20px rgba(0, 0, 0, 0.1);
                    height: 63px;
                width: 63px;
                margin:auto auto 1rem;
                display: flex;
                justify-content: center;
                align-items: center;
                border-radius: 50%;
                font-family: $font-helvetica-neue;
font-style: normal;
font-weight: bold;
font-size: 36px;
line-height: 44px;



color: #000000;


                   }
            }
        }

        .Rating {
            padding: 2rem 0;

            @media (max-width: 1200px) {
                padding: 2rem 2rem 3.5rem;
            }


            .Img {
                &__Container {
                    width: 100%;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                margin-bottom: 30px;
               margin:auto;
                  &--0{
                    @media (max-width: 992px) {
                    width: 168.74px;

                    img{
                        height: auto;
                        width: 100%;
                      }
                        }
                       
                  }

                  &--1{
                    @media (max-width: 992px) {
                        width:65px;

                        img{
                            height: auto;
                            width: 100%;
                        }
                        }
                        
                  }


                  &--2{
                    @media (max-width: 992px) {
                        max-width:117px;

                       
                        }
                  }







                    &--3{
                     max-width: 100.44px;
                     margin:auto;

                     @media (max-width: 992px) {
                     max-width: 74.82px;

                     }
                     img{
                         width: 100%;
                     }
                    }


                  &--4{
                    @media (max-width: 992px) {
                        max-width: 208px;
                        img{
                            width: 100%;
                        }
                        }
                       
                  }

                }
            }

            .Text {
                &__Container {
                    @media (max-width: 992px) {
                    margin-top: 2rem;

                    }
                    h4 {
                        font-family: $font-helvetica-neue;
                        font-style: normal;
                        font-weight: bold;
                        font-size: 24px;
                        line-height: 29px;
                         margin-top: 2.4rem;   
                        margin-bottom:2.4rem;
                        color: #000000;
                        text-align: center;
                        @media (max-width: 1199px) {
                            text-align: left;
                            max-width: 642px;
                            margin: 1.2rem  auto;
                        }
                    }

                    p {
                        font-family: $font-avenir;
                        font-style: normal;
                        font-weight: 500;
                        font-size: 16px;
                        line-height: 22px;
                        max-width: 642px;
                        text-align: left;
                        color: #686868;
                        text-align: center;
                        margin: 0 auto;
                        @media (max-width: 1199px) {
                            text-align: left;
                          
                          
                        }
                    }
                }
            }
        }
    }
}