.SectionProducts {
  display: grid;
  grid-gap: 16px;
  margin: 0 auto 84px;
  padding:0 2vw;
  justify-content: center;
  grid-template-columns: repeat(auto-fill, minmax(160px, 160px));
  @media (min-width: 992px) {
    justify-content: flex-start;
    grid-gap: 33px;
    padding:0;
    grid-template-columns: repeat(auto-fill, minmax(320px, 320px));
  }

  @media (min-width: 1440px) {
    max-width: 100%;
    justify-content: center;
  }
  .Card {
    &__ProductContainer {
      width: 160px;
      @media (min-width: 992px) {
        width: 320px;
      }
      .Product {
        border: 2px solid rgba(230, 232, 234, 0.54);
        box-sizing: border-box;
        border-radius: 20px;
        width: 100%;
        display: flex;
        justify-content: center;
        position: relative;
 
        cursor: pointer;
        padding:1rem;
        align-items: center;
        height: 190px;
        @media (min-width: 992px) {
          height: 380px;
        }
        .Approved {
          height: 16px;
          display: flex;
          justify-content: center;
          align-items: center;
          width: 100px;
          background: #000000;
          border-radius: 12px 0px 12px 0px;
          font-family: $font-helvetica-neue;
          font-style: normal;
          font-weight: bold;
          font-size: 10px;
          line-height: 1.5em;
          display: flex;
          align-items: flex-end;
          text-align: center;

          color: #ffffff;
          position: absolute;
          top: 0;
          left: 0;
          @media (min-width: 992px) {
            width: 129.15px;
            font-size: 12px;
          }
        }

        .Verified {
          position: absolute;
          top: 0;
          right: 0;
          right: 10px;
          top: 10px;
        }
        .ProductImage {
          img {
            max-height: 170px;
            max-width: 100%;
            @media (min-width: 992px) {
              max-height: 250px;
            }
          }
        }

        @media (min-width: 1200px) {
          &:hover {
            border:none;
            box-shadow: 0 15px 32px 0 rgba(0, 0, 0, .3);
          }
        }
      }

      h4 {
        margin-top: 16px;
        font-family: $font-helvetica-neue;
        font-style: normal;
        font-weight: bold;
        font-size: 16px;
        line-height: 20px;

        color: #000000;
        @media (max-width: 991px) {
          font-size: 10px;
          line-height: 12px;
          margin-top: 8px;
        }

      }
      h3 {
        margin: 4px 0 1rem;
        font-family: $font-helvetica-neue;
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 19px;

        color: #000000;
        @media (max-width: 991px) {
          font-size: 10px;
          line-height: 12px;
        }
      }
    }
  }
}
