.Navbar {
  background-color: white;
  box-shadow: rgba(0, 0, 0, 0.13) 0px 2px 6px 0px;
  height: 86px;
  display: flex;
  align-items: center;
  z-index: 2;
  position: relative;

  @media (max-width:992px) {
    height: 68px;

  }

  &__Container {
    margin: 0 auto;
    width: 100%;
    max-width: 1440px;
  }

  &__Logo {
    height: 45px;    
    width: 141px;
    margin-left: 12px;
    height: auto;
    @media (max-width: 992px) {
      margin-left: 5px;

  

    }
  }

  &__Item {
    background-color: white;
  }

  &__Items-container {
    width: 100%;
    display: flex;
    justify-content: center;

    @media (min-width:1200px) {
      margin-right: 177px;
    }
  }

  .Dropdown {
    &--Active {
      font-weight: 600;

    }
  }

  .Navbar__Item {
    border: none;
    font-family: $font-helvetica-neue;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
line-height: 17px;
cursor: pointer;
background-color: black;
margin:0 24px;
letter-spacing: 0.1em;
text-transform: uppercase;

padding: 0 0 2px 0;

  &--Active{
    font-weight: bold;
    font-family: $font-helvetica-neue;
font-style: normal;
font-weight: bold;
font-size: 14px;
padding:2px 0;

letter-spacing: 0.1em;
color: black;
border-radius: 0;
  }
}
.MuiTypography-body1{
  color:black!important;
  font-size:18px!important;
  font-weight:bold!important;
  font-family:$font-avenir!important;
}

}


.MuiInputBase-input {
  &::placeholder{
   font-family: $font-helvetica-neue !important;


   font-size: 15px !important;
   line-height: 18px;
   opacity: 1!important;
   color: #000000 !important;
  }
  
 }
