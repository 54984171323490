.IndustryNavigation {
    max-width: 1023px;
    margin: 0 auto;
    padding: 40px 0;
    @media (max-width: 1199px) {
        padding: 40px;
    }
    h2 {
        font-family: $font-helvetica-neue;
        font-style: normal;
        font-weight: bold;
        font-size: 24px;
        line-height: 29px;
        text-align: left;
        color: #000000;
        
        @media (max-width: 1199px) {
         max-width: 282px;
         margin: auto;
         padding-bottom: .5rem;
        }

        @media (min-width: 1200px) {
        font-family: $font-inter;
        font-style: normal;
        font-weight: bold;
        font-size: 28px;
        line-height: 34px;
        text-align: center;


        }

    }

    div {
        &.Line {
            max-width: 685px;
            margin: 22px auto;
            opacity: 0.4;
            border-bottom: 0.75px solid #000000;
        }

        &.NavContainer {
            max-width: 685px;
            margin: 0 auto;
            display: flex;
            justify-content: space-between;

            button {
                cursor: pointer;
                background-color: transparent;
                border: none;
                font-family: $font-helvetica-neue;
                font-style: normal;
                font-weight: normal;
                font-size: 14px;
                line-height: 24px;

                @media (min-width: 1200px) {
                    font-size: 20px;

                }

                color: #000000;

                &:hover,
                &:active,
                &:focus {
                    border: none;
                    outline: none;
                    opacity: .8;


                }

                &.active {
                    font-family: $font-helvetica-neue;
                    font-style: italic;
                    font-weight: bold;
                    font-size: 14px;
                    line-height: 24px;

                    color: #000000;
                    @media (min-width: 1200px) {
                        font-size: 20px;
                    }

                }

            }
        }
    }
    .Dropdown{
        display: flex;
        justify-content: center;
    }
}