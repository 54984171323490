.TopStories {
    background-color: white;

    @media (max-width: 991px) {
        padding: 2rem;
        justify-content: center;
        display: flex;
        flex-direction: column;
        
        align-items: center;
    }

    &__Container {
          
           max-width: 853px;
         
           margin:auto;
        @media (min-width: 992px) {
            margin-bottom: 0;
        }
    }

    &__Container {
        .TopStory {
            &__Container {
                border-radius: 20px 20px 0 0;
                margin-bottom: 24px;
                width: 100%;
                max-width: 335.95px;
                display: grid;
                justify-content: center;
                cursor: pointer;
                box-shadow: 2px 2px 20px rgba(0, 0, 0, 0.1);

                &:hover {
                    opacity: .9;
                }

                @media (min-width: 992px) {
                    max-width: 853px;
                    margin-bottom: 40px;
                    grid-template-columns: 369.19px auto;
                }

                .Img {
                    &__Container {
                        max-width: 335.95px;
                        height: auto;

                        @media (min-width: 992px) {
                            max-height: 327.65px;
                            height: 327.65px;
                        }

                        img {
                            width: 100%;
                            object-fit: cover;
                            max-width: 335.95px;

                            @media (min-width: 992px) {
                                width: 369.19px;
                                max-width: 369.19px;
                                height: 327.65px;
                                border-top-left-radius: 20px;
                                object-position: left;
                                object-fit: cover;
                            }
                            @media (max-width: 991px) {
                             border-radius: 20px 20px 0 0;

                            }
                        }
                    }
                }

                .Text {

                    &__Container {

                        height: auto;
                        padding: 12px 10px 13px 12px;
                      
                        @media (min-width: 992px) {
                            max-height: 305px;
                            padding: 2rem 32px 32px 32px;

                        }

                        display: flex;
                        flex-direction: column;

                        .heading {
                            align-items: center;
                            display: flex;
                            justify-content: space-between;

                            span {
                                font-family: $font-helvetica-neue;
                                font-style: normal;
                                font-weight: normal;
                                font-size: 14px;
                                line-height: 17px;

                                color: #919191;


                            }

                        }

                        h6 {
                            font-family: $font-helvetica-neue;
                            font-style: normal;
                            font-weight: 550;
                            font-size: 14px;
                            line-height: 17px;
                            text-transform: uppercase;
                            color: #919191;
                             margin:0;
                            @media (min-width: 992px) {
                                font-size: 16px;
                                line-height: 20px;
                                margin-bottom: 4px;
                                color: #919191;


                            }
                        }


                        h3 {
                            font-family: $font-helvetica-neue;
                            font-style: normal;
                            font-weight: bold;
                            font-size: 20px;
                            line-height: 24px;
                            margin: 5px 0 14px;
                            color: #000000;

                            @media (min-width: 992px) {
                                font-size: 36px;
                                line-height: 44px;

                            }
                        }

                        p {
                            font-family: $font-avenir;
                            font-style: normal;
                            font-weight: 300;
                            font-size: 16px;
                            line-height: 20px;
                            min-height: 120px;
                              margin: 0;
                              overflow: hidden;
                            color: #919191;

                            @media (min-width: 992px) {
                           
                                max-width: 393px;
                                font-size: 14px;
                                    

                                color: #919191;


                            }
                          
                        }

                        .Date {
                            &__Row {
                                margin-top: 1rem;
                                display: flex;
                                justify-content: space-between;
                                align-items: center;

                                span {
                                    font-family: $font-helvetica-neue;
                                    font-style: normal;
                                    font-weight: normal;
                                    font-size: 14px;
                                    line-height: 17px;

                                    color: #C4C4C4;

                                    @media (min-width: 992px) {
                                        font-size: 16px;
                                        line-height: 19px;

                                        color: #919191;


                                    }
                                }

                                img {
                                    cursor: pointer;
                                }

                            }
                        }
                    }

                }
            }
        }


    }

    button {
        background: #000000;
        box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.15);
        border-radius: 0px;
        height: 46.15px;
        width: 143.75px;
        cursor: pointer;
        border-radius: 0px;
        font-family: $font-helvetica-neue;
        font-style: normal;
        font-weight: 600;
        font-size: 18px;

        border: none;
        box-shadow: none;
        color: #FFFFFF;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 2rem;
        padding: 0;

        img {
            margin-top: 2.5px;
            margin-left: 10px;
        }

    }

    &__Title {
        max-width: 852.09px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin: 100px auto 66px;
        @media (max-width: 991px) {
           flex-direction: column;
           margin:2.5rem auto;
        }
        h2 {
            font-family: $font-helvetica-neue;
            font-style: normal;
            font-weight: bold;
            font-size: 28px;
            line-height: 44px;

            color: #000000;

            @media (min-width: 992px) {
                font-size: 36px;
            }

        }

       
    }
}