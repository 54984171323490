.IndustryHome {
  background-color: #fafafa;

  @media (max-width: 1199px) {
    background-color: white;
  }

  &__Container {
    padding: 66px 1rem 86.77px;
    max-width: 1023px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;

    @media (max-width: 1199px) {
      padding: 66px 1rem 16px;
    }

    h2 {
      font-family: $font-helvetica-neue;
      font-style: normal;
      font-weight: bold;
      font-size: 36px;
      line-height: 44px;
      text-align: center;

      color: #000000;

      @media (max-width: 1199px) {
        font-family: $font-inter;

        font-size: 28px;
        line-height: 34px;
        padding: 0 2rem;
      }
    }

    p {
      &.Subtitle {
        font-family: $font-avenir;
        font-style: normal;
        font-weight: normal;
        font-size: 20px;
        line-height: 27px;
        text-align: center;
        letter-spacing: -0.01em;
        max-width: 435px;
        color: #000000;
        margin: auto;
        padding: 20px 0 46px;

        @media (max-width: 1199px) {
          padding: 20px 2rem 46px;
          font-size: 16px;
          line-height: 22px;
        }
      }
    }

    .IndustryHomePosts {
      display: grid;
      grid-gap: 8.34px;
      grid-template-columns: repeat(auto-fill, minmax(162.23px, 162.23px));
      justify-content: center;

      @media (max-width: 1199px) {
        width: 600px;
        max-width: 100%;
        margin: auto;
      }

      @media (min-width: 1200px) {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        grid-gap: 49px;
      }

      div {
        &.Post {
          &:hover {
            opacity: 0.9;
          }

          cursor: pointer;

          background: white;
          box-shadow: 2px 2px 25px rgba(107, 45, 45, 0.1);
          max-height: 370px;
          width: 291px;
          border-top-right-radius: 17px;
          border-top-left-radius: 17px;

          @media (max-width: 1199px) {
            width: 162.2px;
            background-color: white;
          }
        }

        img {
          max-width: 100%;
          width: 291px;
        }

        div {
          &.Info {
            height: auto;
            padding: 20px 8px 11px 21px;
            background-color: white;

            @media (max-width: 1199px) {
              height: auto;
              margin: -3px 0 0;
              padding: 8px 2px 12px 11px;
            }
          }

          div {
            display: grid;
            grid-template-columns: 2fr 1fr;

            justify-content: space-between;
            grid-gap: 8%;

            @media (max-width: 1199px) {
              grid-gap: 4%;
            }
            p {
              font-family: $font-helvetica-neue;
              font-style: normal;
              font-weight: bold;
              font-size: 12px;
              line-height: 1.2em;
              padding: 0;
              margin: 0;
              letter-spacing: -0.01em;
              color: #919191;
              text-transform: uppercase;

              @media (max-width: 1199px) {
                font-weight: bold;
                font-size: 8px;

                letter-spacing: -0.01em;
              }
            }

            span {
              margin: 0;
              font-family: $font-helvetica-neue;
              font-style: normal;
              font-weight: bold;
              font-size: 12px;
              line-height: 84.6%;
              letter-spacing: -0.01em;
              color: #919191;

              @media (max-width: 1199px) {
                font-size: 8px;
                line-height: 10px;
              }
            }
          }

          p {
            margin-top: 13px;
            font-family: $font-helvetica-neue;
            font-style: normal;
            font-weight: bold;
            font-size: 18px;
            line-height: 22px;

            color: #000000;

            @media (max-width: 1199px) {
              font-size: 12px;
              line-height: 15px;
              margin-top: 5px;
            }
          }
        }
      }
    }
    .Button {
      &__container {
        margin: auto;
        width: 502px;
        margin: auto;
        display: flex;
        justify-content: flex-start;
        max-width: 100%;
        button {
          margin: 40px auto 0;
          padding: 0 20px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          background: #000000;
          border: 1px solid #000000;
          color: white;
          box-sizing: border-box;
          box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.15);
          border-radius: 0px;
          max-width: 100%;
          height: 55px;
          width: 149px;
          cursor: pointer;
          font-family: $font-helvetica;
          font-style: normal;
          font-weight: 600;
          font-size: 16px;
          line-height: 20px;
          text-transform: uppercase;

          color: #ffffff;

          @media (max-width: 1199px) {
            margin: 40px 0 0 8px;
          }

          &:hover {
            opacity: 0.9;
          }
        }
      }
    }
  }
}
