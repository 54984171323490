.Block{
    background-color: white;

    &--heading{
    
        width: 100%;
        max-width: 1200px;
        margin:auto;
        padding:3rem 0;
        text-align: center;
        @media (min-width: 992px) {
            margin:5rem auto 3rem;
        }
    }


    &--green{
        display:none;
        @media (min-width: 992px) {
        display:block;
   background-color:#4E6863;
   
        min-height: 28.65px;

        }
    }
    
    &--slim{
       margin-left: auto;
       margin-right: auto;
        @media (min-width: 1200px) {
            max-width: 1024px;
            margin:5rem auto 2rem;
        }
      
  
    }
 &--small{

       margin-left: auto;
       margin-right: auto;
        @media (min-width: 1200px) {
            max-width: 1024px;
            margin:3.1rem auto 0;
        }
      
  
    }

    &--container-slim{

        margin: 0 auto;
        @media (min-width: 1200px) {
            max-width: 1023px;
        }
    }

    &--black{
        background-color: black;
        margin:0 auto;
        padding:43.93px 0;
        color:white;
        @media (min-width: 1200px) {
        padding:163.89px 0 72.11px;
        }
    }
    &--simple-black{
        background-color: black;
        margin:0 auto;
      
       
    }
    &--black-slim{
        background-color: black;
        margin:0 auto;
        padding:3rem 2rem;
        color:white;
        @media (min-width: 1200px) {
        padding:7rem 0 2rem;
        }
    }
    &--gray{
        background: #fafafa;
        min-height: 22.05px;
        @media (min-width: 992px) {
            min-height: 40.5px;
        }
    }

}