.Testimonials {
    &__Container {
        padding: 2rem 1.2rem 4rem;
        background-color: white;
        margin: 0 auto;

        @media (min-width: 1200px) {
            max-width: 1023px;
            padding: 7.5rem 1.2rem 12.5rem;
        }

        >div {
            @media (min-width: 1200px) {
                display: flex;
                justify-content: center;
                flex-direction: column;
            }

            .Quote {


                &--mark {
                    display: flex;
                    margin: 2rem auto 3rem;

                    &--Desktop {

                        display: none;
                    }

                    @media (min-width: 1200px) {


                        &--2,
                        &--1 {

                            display: none;
                        }

                        &--Desktop {

                            display: block;
                        }
                    }

                }

                &--text {
                    font-family: $font-helvetica-neue;
                    font-style: normal;
                    font-weight: 500;
                    font-size: 28px;
                    line-height: 37px;
                    text-align: left;

                    color: #000000;
                    max-width: 630px;
               
                    margin: auto;
                    @media (max-width: 1199px) {

                        max-width: 294px;

                    }
                    @media (min-width: 1200px) {
                        font-size: 30px;
                      
                        font-weight: normal;
                        opacity: 1;
                        line-height: 36px;
                        margin-bottom: 6rem;
                        color: #000000;
                        text-align: left;
                    }
                }
            }

            .Description {
                display: flex;
                flex-direction: column;
                justify-content: center;

                img {
                    margin: 2rem auto;
                    width: 65.61px;
                    height: 65.61px;

                    @media (min-width:1200px) {
                        margin: 0 auto 1rem;

                    }
                }

                p {
                    font-family: $font-avenir;
                    font-style: normal;
                    font-size: 18px;
                    line-height: 25px;
                    text-align: center;

                    color: #000000;

                    opacity: 0.7;

                    @media (min-width:1200px) {
                        opacity: 1;
                    }
                }

                &--name {

                    font-weight: 800;

                }

                &--text {
                    margin: 1rem 0;
                    font-weight: 500;
                    color: #686868;

                    opacity: 0.7;

                    @media (min-width: 1200px) {
                        margin: 0 0 1rem;
                    }

                }
            }
        }
    }
}