.Posts {
  background-color: white;
  &__Container {
    max-width: 1023px;
    margin: 0 auto;
    padding: 4rem 2rem;
    background-color: white;
    @media (min-width: 1200px) {
      padding: 4rem 0;
    }
    h2 {
      font-family: $font-inter;
      font-style: normal;
      font-weight: bold;
      font-size: 28px;
      line-height: 34px;

      text-align: left;

      color: #000000;
      @media (min-width: 1200px) {
        font-family: $font-inter;
        font-style: normal;
        font-weight: bold;
        font-size: 36px;
        line-height: 44px;
        padding-bottom: 70px;

        text-align: left;

        color: #000000;
      }
    }

    h6 {
      margin: 13px 0 3rem;
      font-family: $font-helvetica-neue;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 17px;
      text-align: left;

      color: #000000;
    }
  }
  &__Row {
    max-width: 100%;
    @media (min-width: 1200px) {
      display: grid;
      grid-gap: 0 23.76px;
      grid-template-columns: 392.95px auto;
    }
    .Column {
      &--1 {
        @media (max-width: 991px) {
          display: flex;
          justify-content: center;
        }

        .Button {
          display: none;
          @media (min-width: 1200px) {
            border: 2px solid #000000;
            border-radius: 0;
            display: block;
            min-width: 205px;
            height: 46px;

            font-family: $font-helvetica-neue;
            font-style: normal;
            font-weight: 600;
            font-size: 16px;
            line-height: 19px;
            text-align: center;
            letter-spacing: 0.75px;
            cursor: pointer;
            color: white;
            display: inline-flex;
            align-items: center;
            justify-content: center;
            background-color: black;
            margin: 37.76px auto auto;
            padding: 0;
            img {
              margin-left: 81px;
            }
          }

          &__Container {
            display: none;
            max-width: 392.95px;
            @media (min-width: 1200px) {
              display: flex;
              justify-content: center;
            }
          }
        }
      }
      &--2 {


      .Post{
        &__Link{
          &--0, &--1, &--2, &--3{
            &:hover{
              opacity: .9;
            }
          }
        }
      }
        @media (max-width: 1199px) {
          display: flex;
          justify-content: center;
          flex-direction: column;
        }
        @media (min-width: 1200px) {
          display: grid;
          grid-gap: 44.89px 27.93px;

          grid-template-columns: repeat(2, 286.4px);
        }
        .Button {
          font-family: $font-helvetica-neue;
          font-style: normal;
          font-weight: 500;
          font-size: 18px;
          line-height: 22px;
          background-color: black;
          border: none;
          box-shadow: none;
          font-style: normal;
          font-weight: 500;
          font-size: 18px;
          line-height: 22px;
          height: 46px;
          cursor: pointer;
          width: 143px;

          display: inline-flex;
          align-items: center;
          justify-content: center;
          color: #ffffff;

          img {
            margin-left: 14px;
          }

          @media (min-width: 1200px) {
            display: none;
          }
        }
      }

    }
    .Post {
      display: flex;
      align-items: center;
      flex-direction: column;
      box-shadow: 2px 2px 25px rgba(0, 0, 0, 0.1);
       &--0{
         &:hover{
           opacity: 0.9;
         }
       }
      @media (max-width: 1199px) {
        max-width: 328.77px;
        margin: auto auto 35px;
      }
      @media (min-width: 1200px) {
        align-items: initial;
        max-width: 286.4px;
        box-shadow: 2px 2px 25px rgba(0, 0, 0, 0.1);
      }
      img {
        width: 372px;
        height: auto;
        max-width: 100%;

        @media (min-width: 1200px) {
          object-fit: cover;
          width: 286.4px;
          height: 233.06px;
          margin-bottom: 8px;
        }
      }
      .category {
        &--large {
          text-transform: uppercase;

          margin-bottom: 2px;
          font-family: $font-helvetica-neue;
          font-style: normal;
          font-weight: 550;
          font-size: 16px;
          line-height: 20px;

          color: #919191;
          display: block;
        }
      }
      .larger-post-date {
        font-family: $font-helvetica-neue;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 19px;
        margin-bottom: 11.74px;
        margin-left: 11.94px !important;
        text-transform: capitalize;
        color: #919191 !important;
      }
      .Category__Row {
        display: block;
        width: 100%;
        padding: 0 0 0.5rem 12px;
        max-width: 372px;
        @media (min-width: 1200px) {
          display: flex;

          max-width: 169.42px;
          padding: 0 0 10.64px;
        }
        &--large {
          @media (min-width: 1200px) {
            max-width: 169.42px;
            span {
              &:nth-of-type(1) {
                @media (min-width: 1200px) {
                  margin-left: 13.98px;
                }
              }
            }
          }
        }
      }
      span {
        font-family: $font-inter;
        font-style: normal;

        font-size: 14px;
        line-height: 19px;

        max-width: 363px;
        color: #565656;
        text-transform: uppercase;

        font-weight: bold;
        @media (min-width: 1200px) {
          font-family: $font-helvetica-neue;
          font-size: 16px;
          text-transform: capitalize;
          padding-bottom: 0;
          font-weight: normal;
        }
        &:nth-of-type(1) {
          color: #919191;

          @media (min-width: 1200px) {
            margin-left: 9px;
          }
        }
        &:nth-of-type(2) {
          margin-left: 5px;
          color: #c4c4c4;
        }
      }
      h3 {
        font-family: $font-inter;
        font-style: normal;
        font-weight: bold;
        font-size: 28px;
        line-height: 32px;
        max-width: 373px;
        color: #000000;
        padding: 11px 0;
        @media (max-width: 1199px) {
          margin-left: 11px;

          font-family: $font-helvetica-neue;
          font-style: normal;
          font-weight: bold;
          font-size: 24px;
          line-height: 29px;

          color: #000000;
        }
        @media (min-width: 1200px) {
          font-family: $font-helvetica-neue;
          font-size: 20px;
          line-height: 24px;
          font-weight: bold;
          padding: 0 9px 12px 9px;
          min-height: 64px;
        }
      }
      p {
        font-family: $font-avenir;
        font-style: normal;
        font-weight: 300;
        font-size: 16px;
        line-height: 20px;
        margin-left: 11px;
        /* or 125% */
        padding-bottom: 1rem;

        color: #919191;

        @media (min-width: 1200px) {
          font-family: $font-avenir;
          font-style: normal;
          font-weight: 500;
          font-size: 18px;
          line-height: 25px;
          max-width: 373px;

          color: #919191;
          padding-bottom: 1.2rem;
          margin: 0 24px 0 11.98px;
        }
      }
      &--3,
      &--4 {
        display: none;
        @media (min-width: 1200px) {
          display: block;
        }
      }
      &--0 {
        max-width: 392.95px;
        margin: auto;
        @media (max-width: 1199px) {
          margin-bottom: 35px;
          max-width: 328.77px;
        }
        @media (min-width: 1200px) {
          box-shadow: 2px 2px 25px rgba(0, 0, 0, 0.1);
          img {
            width: 100%;
            height: 255.71px;
            object-fit: cover;
          }
          h3 {
            font-family: $font-inter;
            font-style: normal;
            font-weight: bold;
            font-size: 28px;
            line-height: 34px;
            max-width: 401px;
            color: #000000;
          }
          .Category {
            &__Row {
              display: none;
            }
          }
        }
      }
      &__Link {
        text-decoration: none;
      }
    }
  }
  .btn {
    &--mobile {
      display: flex;
      justify-content: flex-end;
      width: 328.77px;
      max-width: 100%;
      margin: auto;
    }
  }
}
