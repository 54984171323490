.Categories{
    background-color: white;
    &__Container{
        max-width: 1093px;
        margin:0 auto;
        padding: 4rem 1rem;
        background-color: white;
        @media (min-width: 1200px) {
            padding: 9rem 0 213px;
        }

        p{
            text-align: center;
            font-family: $font-helvetica-neue;
font-style: normal;
font-weight: 600;
font-size: 16px;
line-height: 84.6%;
text-align: center;
letter-spacing: -0.01em;
color: #919191;
padding-bottom: 12px;

        }
        h2{
            font-family: $font-inter;
            font-style: normal;
            font-weight: bold;
            font-size: 28px;
            line-height: 29px;
            text-align: left;
            margin: auto;
            padding-bottom: 42px;
            color: #000000;
            text-decoration: none;
            max-width: 311px;
            padding-left: 8px;
            text-align: center;
            @media (min-width:1200px) {
                max-width: 1023px;
     text-align: center;
     font-size: 36px;
     padding-bottom: 82px;
     line-height: 44px;
     margin: auto;
     padding-left: 0;
     text-align: center;
            }
        }
        .Category{
            justify-content: center;
          
            display: grid;
            grid-template-columns: repeat(auto-fill, minmax(158px, 158px));
               justify-content: center;

               @media (max-width:1199px) {
               max-width: 400px;
               margin: auto;
               }
                @media (min-width:1200px) {
                    display:flex;
                    flex-wrap: wrap;
                  
                    gap:64px;
                }

                &__Container{

                    @media (max-width:1199px) {
                    margin: auto;
                    width: 157.77px;
                    max-width: 100%;
                    }
                  
                    @media (min-width:1200px) {
                   width: 318px;

                    }
                }
            &__Image{
                width: 100%;
             max-width: 318px;
             height:auto;
            max-height: 147px;
            @media (min-width:1200px) {
        max-height: 315.017px;
            }
            &:hover{
                opacity: .9;
            }
            }
            &__Link{
                text-decoration: none;
          outline:none;
          @media (max-width:1199px) {
              margin:5.5px;
              filter: drop-shadow(0px 4px 10px rgba(123, 123, 123, 0.25));
          }
              
            }
            &__Title{
                position: relative;
                bottom: 3px;
                height: 64.26px;
                background-color: #F8F6F2;
                font-family: $font-helvetica-neue;
                font-style: normal;
                font-weight: 600;
                font-size: 20px;
                line-height: 24px;
                text-align: center;
                padding: 1rem 0 2rem;
                color: #000000;
                text-decoration: unset;
                border-bottom-right-radius: 20px;
                border-bottom-left-radius: 20px;
                @media (max-width:1199px) {
                    font-size: 14px;
height: 31.76px;
padding:1px 0 6px;
                }
               
            }
        }
    }
}