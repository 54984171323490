.about-hero {
    &__container {
        padding: 1rem 0;
        margin: 0 auto;

        max-width: 1023px;

        @media (min-width: 1200px) {
            padding:129px 0  225px;
        }

        .logos {
            @media (min-width: 1200px) {}

            img {

                @media (min-width: 1200px) {}
            }

            div {
                   display: grid;
                   max-width: 634.78;
                   grid-gap:66.46px;
                   justify-content: center;
align-items: center;
                   grid-template-columns: 219.14px 349.18px;
                &.row{
                    &:first-of-type{
                      
                    }
                    &:nth-of-type(2){
                       
                    } 
                }
                &.centered {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    margin-bottom: 1.5rem;
                    img{
                        &:first-of-type{
                            position: absolute;
                        }
                        &:nth-of-type(2){
                            position: relative;
                        }
                    }
                    @media (min-width: 1200px) {}
                }
            }
        }

        .grid {
            &__container {
            
                @media (min-width: 1200px) {
                    display: grid;
                    margin-top: 151px;
                    grid-gap:96.29px;
                    grid-template-columns: 1fr 1fr;
                }
                  
                &--images {
                   width: 318px;
                   max-width: 100%;
                 
                 margin: 0 auto -2rem;
                   
                 @media (max-width: 1199px) {
                     overflow:hidden;
                 }
                    img {
                        cursor: pointer;
                        &:nth-of-type(1){
                            position: relative;
                            max-width: 100%;
                            z-index: 2;
                          
                            @media (min-width: 1200px) {
                                right: -15%;   
                         
                                width: 369.50653076171875px;
                             
                                
                                max-width:369.50653076171875px;
                            }

                        }
    
                        &:nth-of-type(2){
                            position: relative;
                            left: 39%;
                            top: -4.5rem;
                            max-width: 100%;
                            @media (min-width: 1200px) {
                               top:0;
                                width: 477.0px;
                                left: -35%;
                                max-width: 477.0px;
                            }
                        }
                        &:nth-of-type(3){
    
                            position: relative;
                            left: 2.2rem;
                            top: -4rem;
                            @media (min-width: 1200px) {

                    
                                left: 18rem;
                                top: 0rem;
     
                            }
                        }
                      
                    }
                }

                &--text {
                    padding: 0 2rem;
                    @media (min-width: 1200px) {
                 padding: 0;
                    }

                    h2 {
                        font-family:$font-inter;
                        font-style: normal;
                        font-weight: bold;
                        font-size: 28px;
                        line-height: 34px;
                        padding: 0 0 2rem;
                        /* Black */
                        color: #000000;
                        
                        
                        @media (min-width: 1200px) {

                            font-family: $font-helvetica;
font-style: normal;
font-weight: bold;
font-size: 48px;
line-height: 55px;

color: #000000;


                        }
                    }

                    p {
padding: 0 0 1.5rem;
                        font-family: $font-helvetica-neue;
font-style: normal;
font-weight: normal;
font-size: 16px;
line-height: 108%;

color: #3A3A3A;

opacity: 0.7;
                        @media (min-width: 1200px) {

                            font-size: 20px;
line-height: 108%;
                        }
                    }

                    img {
                        margin:3rem 0;
                        max-width: 100%;
                        @media (min-width: 1200px) {

                            margin-top: 62px;
                        }
                    }
                }
            }
        }
    }
}