.ShopHomeSection {
  background-color: white;

  &__Container {
    max-width: 1023px;
    margin: 0 auto;
    display: grid;
    padding: 196px 0;
    grid-template-columns: 1fr 2fr;

    @media (max-width: 1199px) {
      padding: 5rem 2rem;
      grid-template-columns: 1fr;
    }
  }

  &__CTA {
    p {
      font-family: $font-helvetica-neue;
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 84.6%;
      letter-spacing: -0.01em;
      color: #919191;
    }
    h6 {
      font-family: $font-helvetica-neue;
      font-style: normal;
      font-weight: bold;
      font-size: 36px;
      line-height: 44px;

      margin: 11px 0 32px;

      color: #000000;
      @media (max-width: 1199px) {
        margin: 11px 0 46px;

        font-family: $font-inter;
        font-size: 28px;
        font-style: normal;
        font-weight: 700;
        line-height: 34px;
        letter-spacing: 0em;
      }
    }
  }

  &__Images {
    @media (max-width: 1199px) {
      display: flex;
      justify-content: center;
      min-height: 300px;
      align-items: center;
    }
    img {
      cursor: pointer;
      @media (max-width: 1199px) {
        width: 188px;
      }
    }
    .Beauty {
      position: relative;
      z-index: 2;

      left: 10.5%;
      bottom: 84px;
      right: 0;
      @media (max-width: 1199px) {
        left: -50px;

        width: 106.56px;
        height: 111.11px;
      }
    }
    .Food {
      position: absolute;
      z-index: 1;
      height: 332px;
      width: 319.2px;
     
      
      @media (max-width: 1199px) {
        height: 219.6px;
        width: 211.17px;
      }
    }

    .Medical {
      top: 221px;
      left: 36%;
      right: 0;

      position: relative;
      z-index: 2;
      @media (max-width: 1199px) {
        left: 40px;
        height: 122.15px;
        width: 117.6px;
        top: 91px;
      }
    }
  }



  button {
    cursor: pointer;
    background: #000000;
    border: 1px solid #000000;
    box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.15);
    border-radius: 0px;
    padding: 0 20px;
    display: flex;
    justify-content: space-between;
    cursor: pointer;
    text-transform: uppercase;
    font-family: $font-helvetica-neue;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    display: flex;
    align-items: center;

    color: #ffffff;
    width: 245.24px;
    height: 55px;

    &:hover {
      opacity: 0.9;
    }
    @media (max-width: 1199px) {
      margin-top: 46px;
    }
    img {
    }
  }
  .ButtonMobile{
    &__container{
      width: 100%;
      margin: auto;
      display: flex;
      justify-content: flex-start;
      max-width: 100%;

      button{
        max-width: 149px;
      }
    }
  }
}
