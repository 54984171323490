.Heading{


    &--h2{
        font-family:$font-helvetica-neue;
        font-style: normal;
        font-weight: bold;
        font-size: 36px;
        line-height: 44px;
        text-align: center;
        color: #212121;
        @media (min-width: 992px) {
         
        }
    }
    &--left{
        @media (min-width: 1200px) {
    
        text-align: left;
  
      
        }
    }
   &--large{
    font-weight: bold;

    line-height: 55px;
    @media (min-width: 1200px) {
        font-size: 48px;
    }
   
   }

  &--industry{
    font-family: $font-inter;
    font-style: normal;
    font-weight: bold;
    font-size: 28px;
    line-height: 34px;
    text-align: center;

    
@media (min-width: 1200px) {
    font-size: 48px;
    text-align: left;
}
  }
  
&--hero{
    font-family: $font-helvetica-neue;
    font-style: normal;
    font-weight: bold;
    font-size: 22px;

    text-align: center;
    
    color: #FFFFFF;
    
    
@media (min-width: 1200px) {
    font-family: $font-helvetica;
    font-size: 48px;
    text-align: left;
    line-height: 55px;
}
}

&--newsletter{
    font-family: $font-helvetica-neue;
    text-align: center;
    font-style: normal;
    font-weight: bold;
    font-size: 22px;
    line-height: 27px;
    
    color: #000000;
    
    
}
&--shop{
    font-family: $font-helvetica-neue;
font-style: normal;
font-weight: bold;
font-size: 20px;
line-height: 24px;
text-align: center;
color: #FFFFFF;
@media (min-width: 992px) {
    font-size: 36px;
line-height: 44px;
}
}
}