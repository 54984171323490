.AppStoreSection{
    &__Container{
        max-width: 1024px;
        margin: 0 auto;
        padding: 83.26px 0 58.91px;
        display: flex;
        justify-content: center;
        flex-direction: column;
        @media (max-width: 1199px) {
            padding: 77px 0 88px;
        }
        h2{
            font-family: $font-helvetica-neue;
font-style: normal;
font-weight: bold;
font-size: 36px;
line-height: 44px;
text-align: center;

color: #000000;
@media (max-width: 1199px) {
    font-family: $font-inter;

    font-size: 28px;
    line-height: 34px;

    
  
    
    
}
        }
        p{
            padding:24px 0 25.5px;
            font-family: $font-helvetica;
            font-style: normal;
            font-weight: normal;
            font-size: 16px;
            line-height: 18px;
            text-align: center;
            
            color: #000000;
            @media (max-width: 1199px) {
                padding: 16px 54px;
            }
        }
        a{
           margin:auto;
           cursor: pointer;
            img{
                &:hover {
                    opacity: 0.9;
                  }
                 
            }
        }
    }
}