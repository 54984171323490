.about-scan {
    padding: 69px 2rem 0;

    @media (min-width: 1200px) {

        margin: 0 auto;

        max-width: 1440px;
    }

    &__container {

        @media (min-width: 1200px) {
            display: grid;
            grid-template-columns: 50% 50%;
            align-items: center;
        }

        .text {

            @media (min-width: 1200px) {
                order: 2;
              
            }

            h6 {
                font-family: $font-helvetica-neue;
                font-style: normal;
                font-weight: 550;
                font-size: 14px;
                line-height: 84.6%;


                letter-spacing: -0.01em;


                color: #141413;

                @media (min-width: 1200px) {

                    font-weight: 600;
                    font-size: 20px;
                    line-height: 84.6%;



                    color: #141413;
                }
            }

            h2 {
                font-family: $font-helvetica-neue;
                font-style: normal;
                font-weight: bold;
                font-size: 20px;
                line-height: 20px;
                padding: 21px 0;
        max-width: 250px;
                color: #141413;


                @media (min-width: 1200px) {
                    font-weight: bold;
                    font-size: 30px;
                    line-height: 100%;
                    padding: 26px 0;
                    max-width: 100%;
                    color: #141413;
                }
            }

            p {
                font-family: $font-helvetica-neue;
                font-style: normal;
                font-weight: normal;
                font-size: 15px;
                line-height: 108%;
                padding: 0 16px 3.5rem 0;
                max-width: 500px;
                color: #3A3A3A;


                @media (min-width: 1200px) {
                    font-size: 22px;
                    line-height: 108%;


                    color: #3A3A3A;


                }
            }
        }

        .img {
overflow: hidden;
            @media (min-width: 1200px) {
                order: 1;
                
                display: flex;
                justify-content: center;
            }

            img {
                position: relative;
                right: 1rem;
          
                @media (min-width: 1200px) {}
            }
        }
    }
}