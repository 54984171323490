.Newsletter {
  @media (min-width: 992px) {
    background-color: black;
    padding: 1rem 2rem 2rem;
  }
  &__Container {
    @media (min-width: 992px) {
      max-width: 1440px;
      margin: 0 auto;
      background-color: black;
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
    }
    .Logo {
      &__Container {
        padding: 2.8rem 0;
        display: flex;
        justify-content: center;
        background: #4e6863;
        @media (min-width: 992px) {
          background-color: black;
          grid-column: 1;
          grid-row: 1;
        }
        img {
          min-width: 237px;
          height: auto;
          @media (min-width: 992px) {
            max-width: 225px;
          }
        }
      }
    }
    .Subscribe {
      &__Container {
        background: #4e6863;
        display: flex;
        align-items: center;
        flex-direction: column;
        .Feedback-Message{
          font-size: 12px;
          margin-top:8px;
          font-family: $font-inter;
          &--error{
            color:rgba(255, 0, 64, 0.883);
          }
        }
        @media (min-width: 992px) {
          background-color: black;
          grid-column: 3;
          grid-row: 1;
          justify-content: center;
        }
        h5 {
          font-family: $font-helvetica-neue;
          font-style: normal;
          font-weight: bold;
          font-size: 22px;
          line-height: 27px;

          color: #ffffff;
          padding-bottom: 1.5rem;
          @media (min-width: 992px) {
            color: #beab84;
            padding-bottom: 0.5rem;
          }
        }
        p {
          font-family: $font-helvetica;
          font-style: normal;
          font-weight: normal;
          font-size: 16px;
          line-height: 18px;

          color: #ffffff;
        }
        img {
          margin: 3rem 0 2.5rem;
          @media (min-width: 992px) {
            display: none;
          }
        }
        form {
          margin-top: 2rem;
          min-width: 283px;
          border: 1px solid white;
          display: flex;
          justify-content: space-between;
          box-sizing: border-box;
          height: 38px;
          @media (min-width: 992px) {
            margin-top: 1rem;
          }
          input[type="email"] {
            padding: 0 0 0 1rem;
            color: white;
            font-family: $font-avenir;
            font-weight: 600;
            border: none;
            background-image: none;
            background-color: transparent;
            -webkit-box-shadow: none;
            -moz-box-shadow: none;
            box-shadow: none;
            outline: none;
            width: 100%;
            height: 38px;
            &::placeholder {
              font-family: $font-helvetica;
              font-style: normal;
              font-weight: normal;
              font-size: 14px;

              color: #ffffff;
            }
          }

          button {
            border: none;
            background-color: transparent;
            -webkit-box-shadow: none;
            -moz-box-shadow: none;
            box-shadow: none;
            height: 38px;
            cursor: pointer;
outline:none;
            img {
              display: block;
              margin: 0;
            }
            &:hover {
              opacity: 0.8;
            }
          }
        }
      }
    }

    .Social {
      &__Container {
        background-color: black;
        padding: 3rem 2rem 1rem;
        display: flex;
        flex-direction: column;
        align-items: center;
        @media (min-width: 992px) {
          background-color: black;
          grid-column: 2;
          justify-content: center;
          grid-row: 1;
        }
        > div.Icons {
          padding: 2.5rem 0 2rem;
          width: 100%;
          max-width: 250px;
          display: flex;
          justify-content: space-between;
          outline:none;
          a{
            
        opacity: 1;
        transform: scale(1);
        transition: opacity 0.3s, transform 0.3s;
        -webkit-transition: opacity 0.3s, transform 0.3s;
        &:hover {
          opacity: 0.65;
          transform: scale(1.1);
          transition: opacity 0.5s, transform 0.5s;
          -webkit-transition: opacity 0.5s, transform 0.5s;
        
      }
          }
        }
        p {
          max-width: 253px;
          font-family: $font-helvetica-neue;
          font-style: normal;
          font-weight: bold;
          font-size: 24px;
          line-height: 84.6%;

          /* or 20px */

          color: #ffffff;
          @media (min-width: 992px) {
            line-height: 29px;
          }
        }
        a {
          img {
           
          }
        }
        > div.Links {
          font-family: $font-helvetica-neue;
          color: #ffffff;
          .Link {
            font-family: $font-helvetica-neue;
            font-style: normal;
            font-weight: normal;
            font-size: 12px;
            line-height: 84.6%;
            text-decoration: none;
            /* or 10px */

            color: #ffffff;

               @media (min-width: 992px) {
             opacity: 1;
        transform: scale(1);
        transition: opacity 0.3s, transform 0.3s;
        -webkit-transition: opacity 0.3s, transform 0.3s;
        &:hover {
          opacity: 0.65;
          transform: scale(1.1);
          transition: opacity 0.5s, transform 0.5s;
          -webkit-transition: opacity 0.5s, transform 0.5s;
        
      }
               }   
          }
        }
      }
    }
  }
}

