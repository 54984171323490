.SingleProduct {
  background-color: white;

  &__Container {
    padding: 4rem 0;
    max-width: 1024px;
    height: auto;

    margin: 0 auto;
    display: grid;
    grid-gap: 40px;
    grid-template-columns: 409px auto;

    @media (max-width: 1199px) {
      padding: 4rem 2rem;
      grid-template-columns: auto;
      width: 440px;
      max-width: 100%;
    }

    .Img {
      border: 0.5px solid rgba(123, 123, 123, 0.25);
      border-radius: 20px;
      display: flex;
      @media (max-width: 1199px) {
        padding: 2rem;
        height: 300px;

        width: 100%;
        margin: auto;
      }
      img {
        margin: auto;
        height: auto;
        max-height: 80%;

        max-width: 70%;
        border-radius: 20px;
        @media (max-width: 1199px) {
        }
      }
      @media (min-width: 1200px) {
        min-height: 438px;
      }
    }

    .Detail {
      max-height: 438px;
      display: flex;
      flex-direction: column;
      justify-content: space-around;

      .Text {
        padding: 0 0 17px 0;
        border-bottom: 0.5px solid #000000;

        h2 {
          font-family: $font-helvetica-neue;
          font-style: normal;
          font-weight: bold;
          font-size: 32px;
          line-height: 44px;
          margin-bottom: 22px;
          color: #000000;

          @media (max-width: 1199px) {
            font-size: 18px;
            line-height: 1.2em;
            max-width: 500px;
            margin: 0 auto 1rem;
          }
        }

        p {
          font-family: $font-helvetica-neue;
          font-style: normal;
          font-weight: 600;
          font-size: 16px;
          line-height: 20px;
          color: #000000;
          margin-bottom: 2px;

          @media (max-width: 1199px) {
            max-width: 500px;
            margin: 0 auto 1rem;
          }

          span {
            margin: 0 0 0 8px;
            font-weight: normal;
          }
        }

        &__Description {
          ul {
            margin-left: 14px;
            list-style: disc;

            li {
              margin: 17px 0 0 0;
              max-width: 537px;
              font-family: $font-helvetica-neue;
              font-style: normal;
              font-weight: 300;
              font-size: 14px;
              line-height: 17px;

              color: #000000;
            }
          }
        }
      }
    }

    .Buttons {
      margin-top: 2rem;
      display: flex;
      flex-direction: column;

      @media (max-width: 1199px) {
        max-width: 500px;
        margin: 2rem auto;
      }

      a {
        text-decoration: none;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 190.01px;
        height: 43.91px;
        background-color: black;
        color: white;
        font-family: $font-helvetica;
        font-size: 14px;
        border-radius: 3px;
        font-weight: 600;
        margin-bottom: 1rem;
      
      }

      .SingleProduct{
         &__view-company {
          display: flex;
          justify-content: center;
          align-items: center;
          position: relative;
        
          background: #000000;
          height: 40px;
          width: 191px;
          border: none;
        
          border-radius: 3px;
          font-family: $font-helvetica;
          font-style: normal;
          font-weight: bold;
          font-size: 14px;
          line-height: 14px;
          text-align: center;
        
          color: #ffffff;
          cursor: pointer;
        }
      }
      
     
      
    }
  }
}


.SingleProduct__icon {
  right: 8%;
  position: absolute;
}
