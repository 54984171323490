.CoverImageHeader {
    &__Container {
        max-width: 1199px;
        max-height: 315px;
        margin: 0 auto;
        display: flex;
        flex-direction: column;
        justify-content: center;

        @media (min-width: 992px) {
            position: relative;
        }

        .CoverImage {
            width: 100%;
            max-height: 315px;
            @media (min-width: 992px) {
                height: 315px;
            }
            img {
                max-width: 100%;
                width: 100%;
                height: 100%;
                object-fit: cover;
                @media (min-width: 992px) {
                    object-fit: contain;
                }
            }
        }

        img {
            &.Favorite {
                max-width: 235px;
                height: auto;
                margin: auto;

                @media (min-width: 992px) {
                    bottom: 0;
                    margin: auto;
                    left: 0;
                    right: 0;
                    position: absolute;
                }
            }
        }
    }
}
