.Paragraph {


    &--normal {
        text-align: center;
        font-family: $font-avenir;
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 22px;
        margin: 1.5rem 2rem 2.3rem;
        color: #686868;


        @media (min-width: 992px) {}
    }

    &--left {

        @media (min-width: 1200px) {
            text-align: left;

            margin-left: auto;
            margin-right: auto;
        }
    }


    &--rating {
        color: white;
        font-weight: 500;
        font-family: $font-avenir;
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 22px;
        padding: 2rem 0;
        color: #FFFFFF;
        text-align: center;

        @media (min-width: 1200px) {
            text-align: left;
            padding-top:.5rem;
            padding-bottom: 0;
        }
          
        @media (max-width: 1199px) {
         max-width: 600px;
         margin: 0 auto;
        }

    }
    &--subtitle{
        font-family: $font-helvetica-neue;
        font-style: normal;
        font-weight: bold;
        font-size: 24px;
        line-height: 29px;
        
        color: #FFFFFF;
        @media (max-width: 1199px) {
            font-size: 20px;
           text-align: center;
           }
         
    }
    &--subtitle{
        font-family: $font-helvetica-neue;
        font-style: normal;
        font-weight: bold;
        font-size: 24px;
        line-height: 29px;
        
        color: #FFFFFF;
        @media (max-width: 1199px) {
            font-size: 20px;
           text-align: center;
           }
         
    }

    &--industry{
        font-family: $font-avenir;
        font-style: normal;
        font-weight: 300;
        font-size: 18px;
        line-height: 25px;
        padding-top: 10px;
        color: #FFFFFF;
        
        margin:1rem 0;
        @media (max-width: 1199px) {
           font-size:16px;
           padding-top: 0;
           text-align: center;
       margin:0;
           }
         
    }

    &--newsletter{
        font-family: $font-helvetica;
        font-style: normal;
        font-weight: normal;
        font-size: 1rem;
        line-height: 18px;
        text-align: center;
        text-align: center;
        color: #686868;
        padding: 1rem 0 3rem;
         
    }
    &--feedback{
        font-family: $font-helvetica;

font-size: 16px;
line-height: 18px;

font-style: normal;
font-weight: normal;
color: #000000;
@media (max-width: 1199px) {
    
    font-size: 14px;
    line-height: 16px;
   
}

    }
    &--auto-complete{
        font-family: $font-helvetica-neue;
        font-style: normal;
        font-weight: bold;
        font-size: 24px;
        line-height: 29px;
        text-align: center;
        color: #FFFFFF;
        margin-bottom: 1.5rem;
        
        @media (min-width: 992px) {
         margin-bottom: 0;
         font-family: $font-inter;
font-style: normal;
font-weight: bold;
font-size: 28px;
line-height: 34px;
margin-right: 4vw;
        }
    }
  
}

