.react-responsive-modal-modal {
  &.customModalFilter {
    border-top-left-radius: 10px;
    padding: 1rem 3rem 4rem;
    border-top-right-radius: 10px;
    width: 100%;
    margin: 0;
    position: fixed;
    top: auto;
    right: 0;
    left: 0;
    max-width: 992px;
    bottom: 0;
  }

  &.customModalRegister {
    background-color: transparent;
    overflow: hidden;
    box-shadow: none;

    .react-responsive-modal-closeButton {
      right: 0;
    }

    .modalRegister {
      margin-top: 62px;
      width: 294px;
      max-width: 100%;

      @media (min-width: 992px) {
        min-width: 789px;
      }

      @media (max-width: 991px) {
        margin: 60px auto 1.5rem;
        padding: 0;
      }

      overflow: hidden;
      background-color: white;
      display: flex;

      @media (max-width: 992px) {
        flex-direction: column;
      }

      .modalImage {
        position: relative;

        img {
          &.product {
            @media (min-width: 992px) {
              height: 100%;
            }
          }

          &.icon {
            position: absolute;
            bottom: 14.3px;
            left: 14.3px;
          }
        }
      }

      .modalText {
        @media (min-width: 992px) {
          padding: 51.17px 54.43px 48.16px 48.61px;
        }

        h1 {
          max-width: 285.1px;

          font-family: $font-helvetica-neue;
          font-style: normal;
          font-weight: bold;
          font-size: 45px;
          line-height: 55px;
          padding-bottom: 22.1px;
          color: #000000;

          @media (max-width: 991px) {
            font-size: 32px;
            padding: 1rem;
            line-height: 1.2em;
            text-align: center;
            margin: auto;
          }
        }

        h6 {
          font-family: $font-helvetica-neue;
          font-style: normal;
          font-weight: 500;
          font-size: 18px;
          line-height: 35px;
          padding: 0 2rem 2rem 0;
          letter-spacing: -0.01em;

          color: #919191;
          @media (max-width: 992px) {
            font-size: 16px;
            line-height: 1.2em;
            padding: 0 1rem 1rem;
          }
        }

        h3 {
          font-family: $font-helvetica-neue;
          font-style: normal;
          font-weight: bold;
          font-size: 35px;
          line-height: 43px;

          /* identical to box height */

          /* Black */
          color: #000000;
          @media (max-width: 992px) {
            font-size: 1.5rem;
            line-height: 1.2em;
            padding: 0 1rem 1rem;
            text-align: center;
          }
        }

        p {
          font-family: $font-helvetica-neue;
          font-style: normal;
          font-weight: 500;
          font-size: 18px;
          line-height: 35px;
          padding: 21px 0 45px;
          /* or 194% */
          letter-spacing: -0.01em;

          color: #919191;
          @media (max-width: 992px) {
            font-size: 16px;
            line-height: 1.2em;
            padding: 0 1rem 3rem;
          }
        }

        span {
          font-size: 12px;
          position: relative;
          top: 1rem;
          display: flex;
          color: crimson;

          @media (max-width: 992px) {
            top: -16px;

            margin: auto;
            line-height: 1.2em;
            text-align: center;

            max-width: 101px;
          }
        }

        form {
          @media (max-width: 992px) {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            margin-bottom: 1.5rem;
          }
          label {
          }

          input[type="email"] {
            width: 259.19px;
            max-width: 100%;
            height: 45.51px;
            border: 1px solid #000000;
            padding: 10px;
          }

          input[type="submit"] {
            margin-left: 1rem;
            width: 112.57px;
            height: 45.51px;
            font-family: $font-helvetica-neue;
            font-style: normal;
            font-weight: normal;
            text-transform: uppercase;
            font-size: 14px;
            line-height: 17px;
            text-align: center;
            border: none;
            cursor: pointer;
            /* White */
            color: #ffffff;

            @media (max-width: 992px) {
              margin: 1rem auto 0;
              width: 100%;
              max-width: 259.19px;
            }

            &.email-submit-button {
              &--black {
                background-color: #000000;
              }

              &--green {
                background: #54efb7;
              }
            }
          }
        }
      }
    }
  }
}
