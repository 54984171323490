// 1. Configuration and helpers


@import 
   'abstracts/variables',
   'abstracts/functions';
// 2. Base stuff
@import
   'base/typography',
   'base/global';
   

// 3. Pages


// 4. Components
@import
'components/AboutHero',
'components/AboutFavorite.scss',
'components/AboutConsumer.scss',
'components/AboutScan.scss',
'components/AboutInfo',
'components/AboutSolution.scss',
'components/AppStoreSection',
'components/Block',
'components/BrandHeader',
'components/BrandRectangle',
'components/Categories',
'components/CategoryRatings',
'components/CollectionSection',
'components/CoverImageHeader',
'components/GlobalRatings',
'components/Footer',
'components/Insights',
'components/IndustryHome',
'components/IndustryNavigation',
'components/Hamburguers',
'components/Heading',
'components/Hero',
'components/Loader',
'components/Modal',
'components/Navbar',
'components/NavigationButton',
'components/NewFooter',
'components/Newsletter',
'components/Paragraph',
'components/Posts',
'components/ProductsSection',
'components/Ratings',
'components/SectionProducts',
'components/SignUpForm',
'components/SingleProduct',
'components/SingleArticle',
'components/ShopHomeSection',
'components/Testimonials',
'components/Titles',
'components/TopStories',
'components/VerifiedBrands';

