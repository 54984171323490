.NavigationButton {

   
    &__Container {
        padding: 2rem 1.2rem;
        background-color: white;
        margin: 0 auto;

        @media (min-width: 1200px) {
            max-width: 1199px;
            padding: 2rem 0;
        }

        button {
            display: flex;
            align-items: center;
            margin: 0 2rem;
            background-color: transparent;
            border: none;
            cursor: pointer;
            font-family: $font-roboto;
            font-style: normal;
            font-weight: bold;
            font-size: 12px;
            line-height: 14px;
            text-transform: uppercase;


            color: #000000;
            img{
                margin-right: 1rem;
            }
            @media (max-width: 1199px) {
                margin: 0;
            }

            &:hover {
                font-weight: bold;
                opacity: .9;
            }

        }
    }
    &--with-cover{
        
        .NavigationButton__Container{
          
            margin: auto;
        background-color: transparent;
        position: absolute;
        
        @media (min-width: 992px) {
            top:0;
        }
        }
      }
}