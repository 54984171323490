.Footer {
  &__Social {
    max-width: 1024px;
    margin: 0 auto;
    display: grid;
    justify-content: center;
    grid-template-rows: 99.94px 187.03px 100px;
    @media (min-width: 1200px) {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      padding: 71px 0 55px;
    }
    .Logo {
      @media (max-width: 1199px) {
        margin: 34.52px auto;
      }
      img {
        &.LogoBuyable {
          width: 220px;
          height: auto;
          @media (max-width: 1199px) {
            min-width: 175px;
            width: 175px;
          }
        }
      }
      a {
        &.Recommend {
          display: flex;
          justify-content: center;
          align-items: center;
          color: white;
          text-decoration: none;
          width: 250.27px;
          height: 47px;
          background: #282828;
          border: 1px solid #282828;
          box-sizing: border-box;
          box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.15);
          border-radius: 0px;

          font-family: $font-helvetica-neue;
          font-style: normal;
          font-weight: bold;
          font-size: 16px;
          line-height: 20px;

          color: #ffffff;
          margin-top: 31.37px;
          &:hover {
            opacity: 0.9;
          }
          &.Icon {
            position: relative;
            left: 10px;
          }
        }
      }

      .Links {
        p {
          color: white;
          padding: 26px 0 14px;
          span {
            .Link {
              font-family: $font-helvetica-neue;
              font-style: normal;
              font-weight: normal;
              font-size: 16px;
              line-height: 84.6%;

              text-decoration: none;
              text-align: center;

              color: #ffffff;
            }
          }
        }
      }

      .Copyright {
        div {
          p {
            font-family: $font-helvetica-neue;
            font-style: normal;
            font-weight: normal;
            font-size: 16px;
            line-height: 19px;

            /* identical to box height */
            text-align: center;

            color: #ffffff;
          }
        }
      }
    }

    .Social {
      a {
        &.Store {
          cursor: pointer;
          display: inline-block;
          height: 72px;
          margin-bottom: 32px;
          @media (min-width: 1200px) {
            margin-bottom: 29.09px;
          }
          img {
            height: auto;
          }
          &:hover {
            opacity: 0.9;
          }
        }
      }
      p {
        font-family: $font-helvetica-neue;
        font-style: normal;
        font-weight: bold;
        font-size: 24px;
        line-height: 29px;
        text-align: center;
        color: #ffffff;
        @media (min-width: 1200px) {
          margin-bottom: 32px;
        }
      }
      &__Recommend {
        display: flex;
        justify-content: center;
        align-items: center;
        font-family: $font-helvetica-neue;
        background: #282828;
        border: 1px solid #282828;
        box-sizing: border-box;
        box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.15);
        border-radius: 0px;
        width: 250.27px;
        height: 47px;
        color: #fff;
        margin-bottom: 2rem;
        font-size: 16px;
        font-weight: bold;
        cursor: pointer;
        text-decoration: none;
      }

      &__Icon {
        position: relative;
        left: 10px;
      }

      .Icons {
        display: flex;
        justify-content: space-between;
        margin: auto;
        max-width: 230px;
        @media (max-width: 1199px) {
          margin: 22px 0 35px;
        }
        .social-icons {
          height: 22px;
          @media (min-width: 1200px) {
            height: 25px;
          }
        }

        @media (min-width: 1200px) {
          max-width: 217px;
        }
        a {
          opacity: 1;
          transform: scale(1);
          transition: opacity 0.3s, transform 0.3s;
          -webkit-transition: opacity 0.3s, transform 0.3s;
          &:hover {
            opacity: 0.65;
            transform: scale(1.1);
            transition: opacity 0.5s, transform 0.5s;
            -webkit-transition: opacity 0.5s, transform 0.5s;
          }
        }
      }
    }
  }

  &__Copyright {
    margin: 1.5rem auto 1rem;
    max-width: 322px;
    padding: 0;
    @media (min-width: 1200px) {
      max-width: 100%;
      padding: 0 0 1.18rem;
      margin: auto;
    }
    p,
    a {
      font-family: $font-helvetica-neue;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 20px;
      text-align: center;
      text-decoration: none;
      color: #ffffff;
      @media (max-width: 1199px) {
        font-size: 10px;
        line-height: 84.6%;
      }
    }

    p {
      span {
        @media (min-width: 1200px) {
        }
      }
    }

    .Link {
      @media (min-width: 992px) {
        opacity: 1;
        transform: scale(1);
        transition: opacity 0.3s, transform 0.3s;
        -webkit-transition: opacity 0.3s, transform 0.3s;

        &:hover {
          opacity: 0.65;
          transform: scale(1.1);
          transition: opacity 0.5s, transform 0.5s;
          -webkit-transition: opacity 0.5s, transform 0.5s;
        }
      }
    }
  }
  &__Block {
    background-color: black;
    height: 56px;
    display: flex;

    align-items: center;
    @media (min-width: 1200px) {
      height: 33px;
    }

    div {
      width: 100%;

      max-width: 1024px;
      margin: 0 auto;
      display: flex;
      align-items: center;
      justify-content: center;
      @media (min-width: 1200px) {
        justify-content: flex-end;
      }
    }
    p {
      font-family: $font-helvetica-neue;
      font-style: normal;
      font-weight: normal;
      font-size: 10px;
      line-height: 84.6%;

      text-align: center;

      color: white;

      @media (min-width: 1200px) {
        font-size: 12px;
        line-height: 14px;
      }
    }
  }
  &__Feedback {
    max-width: 1024px;
    margin: 0 auto;
    padding: 48px 1rem ;
    display: flex;
   h1{
    font-family: $font-helvetica-neue;
    font-style: normal;
    font-weight: bold;
    font-size: 22px;
    line-height: 27px;
    margin-bottom: 21px;
    color: #000000;
   }
   p{
    font-family: $font-helvetica-neue;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 18px;
    text-align: center;
    
    color: #686868;
    margin-bottom: 32px;
    
   }



 
     
      flex-direction: column;
      align-items: center;
      justify-content: center;
    
    button {
      display: flex;
      justify-content: center;
      align-items: center;
      text-decoration: none;
      cursor: pointer;
      background: #ffffff;
      border: 1px solid #ffffff;
      box-sizing: border-box;
      box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.15);
      border-radius: 0px;
      height: 30px;
      width: 128.42px;

      font-family: $font-helvetica-neue;
      font-style: normal;
      font-weight: 600;
      font-size: 14px;

      color: #000000;

   
      &:hover {
        opacity: 0.9;
      }
    }
  }
}
