@font-face {
    font-family: "Avenir";
    src: url("../../assets/fonts/avenir/AvenirLTStd-Roman.otf");
}

@font-face {
    font-family: "Avenir";
    font-weight: bold;
    src: url("../../assets//fonts/avenir/AvenirLTStd-Black.otf");
}

@font-face {
    font-family: "Inter";
    src: url("../../assets/fonts/inter/Inter-Regular.ttf");
}

@font-face {
    font-family: "Inter";
    font-weight: bold;
    src: url("../../assets/fonts/inter/Inter-Bold.ttf");
}

@font-face {
    font-family: "Helvetica Neue";
    font-weight: normal;
    src: url("../../assets/fonts/helvetica-neue/HelveticaNeueLt.ttf");
}
@font-face {
    font-family: "Helvetica Neue";
    font-weight: bold;
    src: url("../../assets/fonts/helvetica-neue/HelveticaNeuBold.ttf");
    

};

@font-face{
    font-family: "Helvetica Neue";
    font-style: italic;
    src: url("../../assets/fonts/helvetica-neue/HelveticaNeueIt.ttf");
}

@font-face{
    font-family: "Roboto";
    font-style: normal;
    src:url("../../assets/fonts/roboto/Roboto-Regular.ttf");
  
  }

$font-inter: 'Inter',
Helvetica,
Arial,
sans-serif;
$font-avenir: 'Avenir',
Helvetica,
Arial,
sans-serif;
$font-helvetica-neue:'Helvetica Neue',
Helvetica,
Arial,
sans-serif;
$font-helvetica:Helvetica,
Arial,
sans-serif;
;
$font-roboto:"Roboto", Arial, sans-serif;
