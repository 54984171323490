.Ratings {
  background-color: white;

  .GlobalRatings {
    &__Title{
      max-width: 1024px;
      font-family:$font-helvetica-neue;
      font-style: normal;
      font-weight: bold;
      font-size: 28px;
      line-height: 34px;
      color: #000000;
      margin: 1rem auto 44px;

     
      @media (max-width: 991px) {
        margin: 1rem auto 2rem ;

        width: 365.17px;
        max-width: 90%;
        
      }
    }
    &__Container {
      max-width: 1024px;
      padding: 4rem 1.2rem 2rem;

      margin: 0 auto;

      padding: 2rem 1.2rem 2rem;
      @media (max-width: 991px) {
        padding: 20px 1.2rem;
        width: 365.17px;
        max-width: 90%;
        box-shadow: 0px 0px 20px rgba(205, 205, 205, 0.4);
        border-radius: 15px;
      }

      h2 {
        font-family: $font-helvetica-neue;
        font-style: normal;
        font-weight: bold;
        font-size: 24px;
        line-height: 29px;
        text-align: center;
        color: #000000;
        @media (max-width: 991px) {
          margin: 0;
          padding: 0 0 1.5rem;
          min-height: 0;

          font-size: 22px;
          line-height: 27px;
        }
      }

      .Ratings {
        display: grid;
        grid-template-columns: 1fr 1fr;

        @media (min-width: 992px) {
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;
          min-height: 93px;
        
          justify-content: center;
        }

        @media (max-width: 991px) {
        }
        .Logo {
          &__Container {
            &__Accredited {
              @media (max-width: 991px) {
                img {
                  &:nth-of-type(1):not(.accredited-logo) {
                    max-width: 25px;
                  }
                  &:nth-of-type(2) {
                    max-width: 65px;
                  }
                }
              }
            }
            &__Glassdoor {
              @media (max-width: 991px) {
                img {
                  max-width: 65.72px;
                }
                p {
                  margin-bottom: 0 !important;
                  font-size: 16px !important;
                }
              }
            }
            .accredited-logo {
              max-width: 89px;
            }
            display: flex;

            align-items: center;

            padding: 1rem;

            @media (max-width: 991px) {
              display: grid;
              grid-template-columns: auto auto;
              grid-gap: 10px;
              justify-content: flex-start;
            }

            @media (min-width: 992px) {
              flex-direction: row;

              padding: 25px;
            }
            p {
              font-family: $font-avenir;
              font-style: normal;
              font-weight: bold;
              font-size: 20px;
              line-height: 27px;
              margin-bottom: 9px;
              /* identical to box height */

              color: #0caa41;

              @media (min-width: 992px) {
                margin-right: 20.87px;
                margin-bottom: 0;
                font-size: 20px;
              }
            }
            &--full {
              @media (max-width: 991px) {
                width: 100%;
                grid-column: 1;
                grid-row: 1;
              }
            }

            &--blogo{
              @media (min-width: 992px) {
                    width: 190px;
                    
                      img{
                        width: 100%;
                        height: auto;
                      }
                    
              }
            }


            &--ewg{
            
              @media (min-width: 992px) {
                width: 100px;
                    
                img{
                  width: 100%;
                  height: auto;
                }

              }
            }


            &:nth-of-type(2) {
              @media (min-width: 992px) {
              }
            }

            img {
              @media (max-width: 992px) {
                max-width: 100%;
              }
              &:nth-of-type(2) {
                @media (min-width: 992px) {
                  margin-top: 0;
                  margin-left: 14px;
                }
              }
            }
          }
        }
      }
    }
    &__Brand {
      @media (min-width: 1200px) {
        padding: 35px 2rem;
        box-shadow: 0px 0px 20px rgba(205, 205, 205, 0.4);
        border-radius: 5px;
        margin-bottom: 22px;
        min-height: 245px;
      }
    }
  }

  .CategoryRatings {
    &__Container {
      max-width: 1024px;
      padding: 2rem 1.2rem 2rem;
      margin: 0 auto;
      h2{
        background-color: white;
        height: initial;
        padding: 0;
      }
      @media (min-width: 1200px) {
        box-shadow: 0px 0px 20px rgba(205, 205, 205, 0.4);
        margin-bottom: 1rem;
        height: 245px;
        overflow-y: hidden;
      }

      @media (max-width: 991px) {
        margin: 40px auto 1rem;
        box-shadow: 0px 0px 20px rgba(205, 205, 205, 0.4);
        border-radius: 15px;
        width: 365.17px;
        max-width: 90%;
        padding: 2rem 0.8rem 2rem;
      }
      h2 {
        font-family: $font-helvetica-neue;
        font-style: normal;
        font-weight: bold;
        font-size: 24px;
        line-height: 29px;
        text-align: center;
        margin-bottom: 1rem;
        color: #000000;
        @media (max-width: 991px) {
          min-height: 0;
          font-weight: bold;
          font-size: 22px;
          line-height: 27px;
        }
      }

      .Ratings {
        @media (max-width: 991px) {
          display: grid;
          grid-template-columns: 50% 50%;
        }
        @media (min-width: 992px) {
          display: flex;

          align-items: center;
        padding:1.5rem 0 1rem;
          flex-wrap: wrap;
          min-height: 93px;
          flex-direction: row;
          justify-content: center;
          width: 100%;
        }
        div {
          margin: auto 0;
          padding: 0.5rem 0 0.5rem 1rem;

          @media (min-width: 992px) {
   
            display: flex;
            margin: 0 25px 25px;
            padding: 0;
            justify-content: center;
          }
        }
       .BunnyContainer{

       
  
        @media (min-width: 992px) {
        margin: -15px 25px 25px;
        }
        img {
          @media (min-width: 992px) {
            max-width: 100%;
          }
        }
      }
        img {
          @media (max-width: 991px) {
            max-width: 100%;
          }

          @media (min-width: 992px) {
            margin: auto;
          }

          &:nth-of-type(4) {
            @media (min-width: 992px) {
              grid-column: 2;
              grid-row: 3;
            }
          }
        }

        .center {
          @media (min-width: 992px) {
            margin-top: 1rem;
            grid-column: 2;
            grid-row: 3;
          }
        }

        .Logo {
          &__Container {
            display: flex;
            align-items: center;
            justify-content: center;

            img {
              &:first-of-type {
                margin-right: 8px;
              }
            }
          }
        }
        @media (min-width: 992px) {   
      &--7{
        div{
          flex-basis: 12%;
        }
      }
      &--6{
        display: grid;
        grid-template-columns: auto auto auto;
        div{
          display: initial;
margin: 0 25px 25px;
padding: 0;
        }
      }
      &--5{
        display: grid;
        grid-template-columns: repeat(12, 1fr);
      
        max-width: 69%;;
   
        margin:auto;
        div:nth-child(-1n + 3) {
          grid-column: span 4;
        }
        div:nth-last-child(2) {
          grid-row-start: 2;
          grid-column: 3 / span 4;
        }
        div:nth-last-child(1) {
          grid-row-start: 2;
          grid-column: 7 / span 4;
        }
      }
  
      &--4{
        display: grid;
        grid-template-columns: auto auto auto;
        div{
          display: block;
         
        }
      }
  
  
    }
   

      }
      &--food{
       
        .Ratings{
          &--5{

            @media (min-width: 992px) {
              display: flex;
              flex-wrap: wrap;
              max-width: 81%;
                div{

              flex: 0 0 19%;
                }
            }
          }
        }
      }
    }
  }
  .MedicationRatings {
    &__Container {
      max-width: 1024px;
      padding: 2rem 2rem 2rem;
      margin: 0 auto;
      @media (min-width: 1200px) {
        padding: 3.7rem 1.2rem 4.5rem;
        box-shadow: 0px 0px 20px rgba(205, 205, 205, 0.4);
        margin-bottom: 1rem;
      }

      h2 {
        font-family: $font-helvetica-neue;
        font-style: normal;
        font-weight: bold;
        font-size: 24px;
        text-align: center;
        line-height: 29px;
        max-width: 415.6px;
        margin: auto;
        color: #000000;
        margin-bottom: 3rem;
        @media (min-width: 1200px) {
          margin-bottom: 42px;
        }
      }

      .MedicationRatings {
        max-width: 415px;
        margin: auto;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
        margin-bottom: 2rem;
        @media (min-width: 1200px) {
          margin-bottom: 47px;
          justify-content: space-around;
        }
        img {
          @media (max-width: 1199px) {
            margin-bottom: 1rem;
          }
          &:nth-of-type(2) {
            @media (min-width: 1200px) {
              margin-right: 1rem;
            }
          }
        }
      }
      .Description {
        max-width: 416.5px;
        margin: auto;
        p {
          font-family: $font-avenir;
          font-style: normal;
          font-weight: 800;
          font-size: 18px;
          line-height: 40px;

          color: #3d3d3d;

          span {
            font-weight: 500;
          }
        }
      }
    }
  }
  .fsc {
    width: 111px;
    img {
      @media (max-width: 991px) {
        width: 100%;
        height: auto;
      }
    }
  }
  .usda{
    width: 100px;
    @media (min-width: 992px) {
      min-width: 150px;
      
        img{
          width: 100%;
          height: auto;
        }
      
}
  }
}
