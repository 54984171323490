.CategoryRatings {
  &__Container {
    margin: 0;
    padding: 1rem 0 2rem;
    @media (min-width: 1200px) {
      margin: 2rem 0 8rem;
      padding: 3rem 0 8rem;
    }
    h2 {
      font-family: $font-helvetica-neue;
      font-style: normal;
      font-weight: bold;
      font-size: 36px;
      line-height: 44px;
      color: #000000;

      @media (max-width: 1199px) {
        height: 78px;
        width: 100%;
        text-align: left;
        margin-bottom: 3.5rem;
        background: rgba(0, 0, 0, 0.05);
        font-family: $font-helvetica-neue;
        font-style: normal;
        font-weight: bold;
        font-size: 24px;
        line-height: 29px;
        padding: 24.63px 0 24.63px 32px;
        color: #000000;
      }
    }
    h3 {
      font-family: $font-inter;
      font-style: normal;
      font-weight: bold;
      font-size: 28px;
      line-height: 34px;
      text-align: center;
      margin-bottom: 3rem;
      color: #000000;
    }
    .Rating {
      padding: 2rem 0;

      @media (max-width: 1199px) {
        padding: 2rem 2rem 4.5rem;
      }

      @media (min-width: 1200px) {
        display: grid;
        grid-template-columns: auto 66%;
      }

      .Img {
        &__Container {
          width: 100%;
          display: flex;
          justify-content: center;
          align-items: center;

          img {
          }
        }
      }

      .Text {
        &__Container {
          h4 {
            font-family: $font-helvetica-neue;
            font-style: normal;
            font-weight: bold;
            font-size: 24px;
            line-height: 29px;
            margin-top: 0;

            color: #000000;
            margin-bottom: 1.2rem;
            @media (max-width: 1199px) {
              text-align: center;
              max-width: 270px;
              margin: 2rem auto;
            }
          }

          p {
            font-family: $font-avenir;
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            line-height: 22px;
            max-width: 568px;
            text-align: left;
            color: #686868;

            @media (max-width: 1199px) {
              margin: 0 auto;
            }
          }
        }
      }
    }
  }
  &__Row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 3rem;
    @media (max-width: 1199px) {
      flex-direction: column;
    }
  }
}
