.about-favorite {
    background: #F8F6F2;
    padding: 56.6px 2rem 0;
    @media (min-width: 1200px) {
        padding: 136px 0 0;
    }
    &__container {
        @media (min-width: 1200px) {
            margin: 0 auto;

            max-width: 1440px;
            display: grid;
            grid-template-columns: 1fr 1fr;

        }

        @media (min-width: 1200px) {}

        .favorite-text {
            @media (min-width: 1200px) {

                margin: 1.4rem auto  0;

            }

            img {
                @media (min-width: 1200px) {}
            }

            h3 {
                font-family: $font-helvetica-neue;
                font-style: normal;
                font-weight: bold;
                font-size: 22px;
                line-height: 27px;
                margin: 24px 0 28px;
                color: #000000;


                @media (min-width: 1200px) {

                    font-size: 36px;
                    line-height: 44px;
                    margin: 37px 0 56px;



                }
            }

            p {
                font-family: $font-helvetica-neue;
                font-style: normal;
                font-weight: normal;
                font-size: 15px;
                line-height: 108%;
                max-width: 500px;
                /* or 16px */

                /* Dark Grey */
                color: #3A3A3A;


                @media (min-width: 1200px) {

                    font-size: 22px;
                    line-height: 108%;
                    max-width: 448.31px;


                }
            }
        }

        .phone {
            display: flex;
            justify-content: flex-end;

            @media (min-width: 1200px) {
                justify-content: center;
            }

            img {
                max-width: 100%;
                position: relative;
                left: 31px;
                overflow: hidden;

                @media (min-width: 1200px) {}
            }
        }
    }
}