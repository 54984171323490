.NewFooter {
  background-color: black;
  &__Container {
    max-width: 1200px;
    margin: auto;
    padding: 71.46px 24px 24px 24px;
    @media (max-width: 991px) {
      padding: 42px 45px 24px 45px;
    }
    .NewFooterLinks {
      display: grid;
      justify-content: space-between;
      grid-template-columns: 319.54px 436.23px 189.53px;
      margin-bottom: 40px;

      @media (max-width: 991px) {
        grid-template-columns: 1fr;
        max-width: 650px;
        margin: 0 auto;
      }

      .Logo {
        img {
          max-width: 195px;

          @media (max-width: 991px) {
            max-width: 175px;
            height: auto;
          }
        }
        p {
          font-family: $font-helvetica-neue;
          font-style: normal;
          font-weight: bold;
          font-size: 24px;
          line-height: 29px;
          margin-top: 1.5rem;
          color: #ffffff;

          @media (max-width: 991px) {
            font-size: 22px;
            line-height: 27px;

            color: #ffffff;
          }
        }
        @media (max-width: 991px) {
          display: flex;
          flex-direction: column;
          justify-content: center;

          margin-bottom: 1rem;
        }
      }
      .Menu {
        display: flex;
        flex-direction: column;

        @media (min-width: 992px) {
          flex-wrap: wrap;
          height: 130px;
        }

        @media (max-width: 991px) {
          margin-top: 17px;
        }
        &__Link {
          font-family: $font-helvetica-neue;
          font-style: normal;

          text-transform: uppercase;
          margin-bottom: 13px;
          text-decoration: none;

          color: #ffffff;
          font-weight: normal;
          font-size: 14px;
          line-height: 17px;

          @media (min-width: 992px) {
            font-size: 16px;

            font-weight: normal;
          }
          &:hover {
            opacity: 0.9;
          }

          &--underline {
            text-decoration: underline;
          }

          &:nth-of-type(5) {
            @media (max-width: 991px) {
              margin-top: 28px;
            }
          }
        }
      }
      .Social {
        @media (max-width: 991px) {
          margin: 2rem 0;
        }
        p {
          text-transform: uppercase;
          margin-bottom: 1.5rem;
          font-family: $font-helvetica-neue;
          font-style: normal;
          font-weight: normal;
          font-size: 16px;
          line-height: 19px;

          color: #ffffff;

          @media (max-width: 991px) {
            font-size: 14px;
          }
        }
        .Icons {
          margin-bottom: 2rem;
          a {
            margin-right: 1.5rem;
            img {
              max-width: 22px;
            }
          }
        }
        a {
          img {
            height: auto;
            max-width: 189.53px;
          }
        }
      }
    }

    .NewFooterPrivacyTerms__Container {
      p,
      .Link {
        font-family: $font-helvetica-neue;
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 84.6%;

        /* or 10px */
        text-align: center;

        color: #ffffff;

        &.legal {
          margin-top: 9px;

          @media (max-width: 991px) {
            font-size: 10px;
            line-height: 84.6%;
          }
        }
      }
    }
  }
}
