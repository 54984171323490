.BrandHeader {
  background-color: white;

  &__Container {
    max-width: 1024px;
    margin: 0 auto;
    padding: 2rem 1.2rem 4rem;

    @media (min-width: 1200px) {
      padding: 1.5rem 0 4rem;
    }

    .Link {
      text-decoration: none;

      @media (min-width: 992px) {
        display: none;
      }
    }

    .BrandDetail {
      p {
        &.BrandContent {
          margin-top: 28px;
          font-family: $font-helvetica-neue;
          font-style: normal;
          font-weight: 300;
          font-size: 14px;
          line-height: 19px;

          color: #000000;

          @media (max-width: 991px) {
            display: none;
          }
        }
      }

      &__Container {
        @media (min-width: 992px) {
          display: grid;
          grid-template-columns: 1fr 1fr;
          justify-content: space-between;
        }

        div {
          &:nth-of-type(1).FirstSection {
            @media (min-width: 992px) {
              display: flex;
              flex-direction: column;
              justify-content: inherit;
              align-items: flex-start;
            }
          }

          &.SecondSection {
            @media (min-width: 992px) {
              display: flex;
              justify-content: center;
              margin-top: 1rem;
              align-self: center;
            }
          }
        }
        h2 {
          &.Company {
            font-family: $font-helvetica-neue;
            font-style: normal;
            font-weight: bold;
            font-size: 36px;
            line-height: 44px;
            text-align: center;
            color: #000000;
            margin-bottom: 30px;
          }
        }

        .BrandImg {
          &__Container {
            width: 186px;
            height: 203px;
            margin: 1.5rem auto 2rem;

            display: flex;
            justify-content: center;
            align-items: center;

            @media (min-width: 992px) {
              margin: 0;
            }

            img {
              object-fit: contain;
              max-width: 100%;
              height: auto;

              @media (min-width: 992px) {
                padding: 5px;
              }
            }
          }
        }

        .BrandText {
          &__Container {
            border-bottom: 0.5px solid #000000;
            padding-bottom: 1.5rem;
            max-width: 257px;
            margin: auto;

            @media (max-width: 991px) {
              border-top: 0.5px solid #000000;
              display: flex;
              flex-direction: column;
              justify-content: center;
            }

            @media (min-width: 992px) {
              max-width: none;
              border: none;
              margin: 0;
              padding: 0;
            }

            img {
              padding-bottom: 15px;

              @media (max-width: 991px) {
              }
            }

            h2 {
              font-family: $font-helvetica-neue;
              font-style: normal;
              font-weight: bold;
              font-size: 36px;
              line-height: 44px;
              text-align: center;
              color: #000000;
              padding-bottom: 1.6rem;
              border-bottom: 0.5px solid #000000;

              @media (min-width: 992px) {
                text-align: left;
                border: none;
                padding-bottom: 1.4rem;
              }
            }

            div {
              @media (min-width: 992px) {
                display: flex;
              }

              p {
                &:first-of-type {
                  padding: 1.5rem 0 0.7rem;
                  font-family: $font-helvetica-neue;
                  font-style: normal;
                  font-weight: 600;
                  font-size: 16px;
                  line-height: 20px;

                  color: #000000;

                  @media (min-width: 992px) {
                    padding: 0 1rem 0.5rem 0;
                  }

                  @media (max-width: 991px) {
                    text-align: center;
                  }
                }

                &:nth-of-type(2) {
                  font-family: $font-helvetica-neue;
                  font-style: normal;

                  font-size: 14px;
                  line-height: 17px;
                  text-align: center;

                  color: #000000;

                  margin-right: 8px;
                  display: flex;
                  justify-content: center;

                  @media (min-width: 992px) {
                    font-weight: normal;
                    font-size: 16px;
                    line-height: 19px;
                    font-style: normal;
                    padding-bottom: 0.5rem;
                  }

                  img {
                    margin-right: 8px;

                    @media (min-width: 992px) {
                      display: none;
                    }
                  }
                }
              }
            }
          }
        }
      }

      a {
        background: #000000;
        border-radius: 3px;
        border: none;
        width: 190px;
        height: 43px;
        font-family: $font-helvetica-neue;
        font-style: normal;
        font-weight: bold;
        font-size: 14px;
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        color: white;
        text-decoration: none;

        &:hover,
        &:active,
        &:focus {
          opacity: 0.9;
          border: none;
          outline: none;
        }

        @media (max-width: 991px) {
          display: none;
        }

        @media (min-width: 992px) {
          margin: 11px;
        }
      }
    }
  }

  .ButtonSection {
    @media (max-width: 991px) {
      a {
        display: flex;
        margin: 51px auto 0;
        width: 218px;
        border-radius: 3px;
        font-weight: bold;
        font-size: 18px;
        line-height: 22px;
      }
    }
  }
}
