.SingleArticle {
    strong {
        font-weight: bold;
    }

    background-color: white;

    &__Container {
        max-width: 1023px;
        margin: 0 auto;
        padding: 69.11px 0 75.51px;
        background-color: white;

        @media (min-width: 1200px) {
            padding: 136.42px 0 109px;
        }

        h1 {
            font-family: $font-inter;
            font-style: normal;
            font-weight: bold;
            font-size: 28px;
            line-height: 34px;
            text-align: center;
            color: #000000;
            padding: 0 2rem 16px;
            max-width: 800px;
            margin: auto;

            @media (min-width: 1200px) {
                font-family: $font-helvetica;
                line-height: 57px;

                font-size: 50px;

                padding-bottom: 1rem;




            }

        }

        .date {
            font-family: $font-helvetica-neue;
            font-style: normal;
            font-weight: normal;
            font-size: 16px;
            line-height: 19px;
            margin-bottom: 8px;
            text-align: center;
            color: #919191;


        }

        .Icons {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 0 2rem;
            max-width: 414px;
            margin: auto;
@media (min-width: 1200px) {
    max-width: 1023px;
    padding: 0 8px;
    position: relative;
    top: 3px;
}

p {
    font-family: $font-helvetica-neue;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;

    color: #919191;


}

div {
    a {
        outline: none;

        img {
            cursor: pointer;
            opacity: 1;
            transform: scale(1);
            transition: opacity 0.3s, transform 0.3s;
            -webkit-transition: opacity 0.3s, transform 0.3s;

            &:hover {
                opacity: 0.9;
                transform: scale(1.1);
                transition: opacity 0.5s, transform 0.5s;
                -webkit-transition: opacity 0.5s, transform 0.5s;

            }
        }

    }

}
}

.Image {
    width: 100%;
    display: flex;
    justify-content: center;

    img {
        max-width: 100%;
        width: 100%;
        height: 571.13px;
        object-fit: cover;
        object-position: top;

        @media (max-width: 1199px) {
            width: 414px;
            height: 294px;
            object-fit: contain;
        }
    }
}

.Text {
    padding: 28px 2rem 1.5rem;
    max-width: 873px;
    margin: auto;

    @media (min-width: 1200px) {
        padding: 42px 8px 42px;

    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        font-family: $font-avenir;

        br {
            display: none;
        }
    }

    p {
        font-family: $font-avenir;
        font-style: normal;
        font-weight: 300;
        font-size: 16px;
        line-height: 20px;

        /* or 125% */

        color: #000000;
        margin-bottom: 24px;

        @media (min-width: 1200px) {
            font-size: 18px;
            line-height: 25px;
            margin-bottom: 24px;
            color: #000000;
        }

        br {}

        span {
            color: #000000;
            font-weight: 600;
        }




    }

    h2,
    h3,
    h5,
    h6,
    h4 {
        font-family: $font-avenir;
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 20px;

        /* or 125% */

        color: #000000;
        margin-bottom: 12px;

        @media (min-width: 1200px) {
            font-size: 18px;
            line-height: 25px;
            font-weight: 550;

            color: #000000;
        }

        br {
            display: none;
        }
    }

    ol,
    ul {
        font-family: $font-avenir;
        font-style: normal;

        font-size: 16px;
        line-height: 20px;
        color: #000000;
        margin-bottom: 24px;

        li {
            margin-bottom: .5rem;
        }
    }

    ol {
        list-style: disc;

        li {
            margin-left: 2rem;
        }
    }

    ul {    font-weight: 600;
               
             
                margin-bottom: 24px;

            }
        }

        .Logo {
            max-width: 873px;
            margin: auto;
            padding-left: 2rem;

            @media (min-width: 1200px) {
                padding-left: 8px;

            }

            div {
                position: relative;
                right: 6.5px;

                @media (min-width: 1200px) {
                    right: 0;

                }

                margin-bottom: 5px;

                a {
                    outline: none;

                    img {
                        cursor: pointer;
                        opacity: 1;
                        transform: scale(1);
                        transition: opacity 0.3s, transform 0.3s;
                        -webkit-transition: opacity 0.3s, transform 0.3s;

                        &:hover {
                            opacity: 0.9;
                            transform: scale(1.1);
                            transition: opacity 0.5s, transform 0.5s;
                            -webkit-transition: opacity 0.5s, transform 0.5s;

                        }
                    }

                }

            }

            img {
                &.Brand {
                    height: 21.99px;
                    width: 129.24px;

                    @media (min-width: 1200px) {
                        height: 33.80px;
                        width: 198.59px;
                        margin-top: 24px;

                    }

                }
            }
        }

    }

    .Articles {
        h3 {
            margin: 54px 2rem 38px;
            font-family: $font-helvetica-neue;
            font-style: normal;
            font-weight: bold;
            font-size: 22px;
            line-height: 27px;


            color: #000000;

            @media (min-width: 1200px) {
                font-family: $font-inter;
                margin: 108px 8px 44px;
                font-size: 28px;
                line-height: 34px;




            }
        }

        .Articles__Container {

            display: flex;
            flex-wrap: wrap;
            padding: 0 2rem;

            @media (min-width: 1200px) {
                display: grid;
                grid-gap: 37px;

                justify-content: center;
                grid-template-columns: repeat(auto-fill, minmax(285.83px, 285.83px));

            }

            .Article {
                &__Container {
                    cursor: pointer;
                    box-shadow: 2px 2px 25px rgba(0, 0, 0, 0.1);
                    border-radius: 20px 20px 0px 0px;
                    max-width: 286.4px;

                    @media (max-width: 1199px) {
                        box-shadow: 2px 2px 20px rgba(0, 0, 0, 0.1);
                        border-radius: 0px;
                        margin: auto;
                        max-width: 100%;
                        width: 336px;
                        margin-bottom: 34px;
                        height: 121.71px;
                    }

                    &:hover {
                        opacity: .9;
                    }

                    &--invisible {
                        display: none;
                    }


                    .Img {
                        &__Container {
                            @media (max-width: 1199px) {
                                display: grid;
                                grid-template-columns: 116.3px auto;
                                height: 100%;
                            }

                            img {

                                max-width: 286.4px;

                                max-height: 356.71px;
                                width: 100%;

                                @media (max-width: 1199px) {
                                    max-width: 116.3px;



                                    width: 100%;
                                }
                            }
                        }
                    }



                    .Text {
                        &__Container {

                            padding: 10px 21px 10px 9px;

                            @media (max-width: 1199px) {
                                display: flex;
                                padding: 4% 1% 4% 5%;
                                flex-direction: column;
                                justify-content: space-between;
                            }

                            p {

                                font-family: $font-helvetica-neue;
                                font-style: normal;
                                font-weight: bold;
                                font-size: 20px;
                                line-height: 24px;

                                color: #000000;
                                margin-bottom: 22px;

                                @media (max-width: 1199px) {

                                    margin-bottom: 0px;
                                    font-size: 18px;
                                    line-height: 22px;




                                }

                                &.Category {
                                    text-transform: uppercase;

                                    font-style: normal;
                                    font-weight: 550;
                                    font-size: 16px;
                                    line-height: 20px;
                                    margin-bottom: 2px;
                                    color: #919191;

                                    @media (max-width: 1199px) {

                                        font-style: normal;
                                        font-weight: bold;
                                        font-size: 14px;
                                        line-height: 17px;

                                        color: #909090;


                                    }
                                }
                            }


                            .Date {
                                &__Row {
                                    display: flex;
                                    justify-content: space-between;
                                    align-items: center;

                                    img {
                                        max-width: min-content;
                                        width: 100%;
                                    }
                                }
                            }

                            span {
                                font-family: $font-helvetica-neue;
                                font-style: normal;
                                font-weight: normal;
                                font-size: 16px;
                                line-height: 19px;

                                color: #919191;

                                @media (max-width: 1199px) {


                                    font-weight: 300;
                                    font-size: 14px;
                                    line-height: 17px;

                                    color: #C4C4C4;


                                }


                            }

                        }
                    }

                }
            }


        }
    }

}