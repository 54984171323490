.Hero {
  position: relative;
  min-height: calc(100vh - 567px);

  @media (min-width: 1200px) {
    min-height: 564px;
  }

  &__Section {
    &--Black {
      background-color: black;

      .Hero {
        &__Container {
          max-width: 1023px;
          padding: 65.76px 2rem 0;

          @media (min-width: 1200px) {
            display: grid;
            grid-template-columns: 64.12% 1fr;
            padding: 106px 0 0;
          }

          .Img__Container {
            margin-bottom: -104px;

            @media (min-width: 1200px) {
              margin: auto auto -64px 25%;
            }
          }
        }
      }

      .Text {
        &__Container {

          div {
            display: flex;
            justify-content: center;
            align-items: center;

            @media (min-width: 1200px) {
              justify-content: flex-start;
              margin-bottom: 1.5rem;
            }

            img {
              height: 100%;

              &:first-of-type {
                width: 32.46px;
                margin-right: 1rem;

                @media (min-width: 991px) {
                  width: initial;
                }
              }

              &:nth-of-type(2) {
                width: 127.38px;

                @media (min-width: 991px) {
                  width: initial;
                }
              }
            }
          }

          @media (max-width: 1199px) {
            margin-bottom: 1rem;
          }

          img {
            padding: 0 0 33px;
            cursor: pointer;

            &:hover {
              opacity: 0.8;
            }

            @media (min-width: 1200px) {
              opacity: 1;
              transform: scale(1);
              transition: opacity 0.3s, transform 0.3s;
              -webkit-transition: opacity 0.3s, transform 0.3s;

              &:hover {
                object-fit: contain;
                max-width: none;
                opacity: 0.9;
                transform: scale(1.009);
                transition: opacity 0.5s, transform 0.5s;
                -webkit-transition: opacity 0.5s, transform 0.5s;

              }

              padding: 1rem 0 30px;



            }
          }

          h6 {
            font-family: $font-helvetica-neue;
            font-style: normal;
            font-weight: 300;
            font-size: 25px;
            line-height: 30px;
            text-align: center;
            margin-bottom: 4.5rem;
            color: #ffffff;
          }

          h2 {
            font-family: $font-inter;
            font-style: normal;
            font-weight: bold;
            font-size: 30px;
            line-height: 36px;
            text-align: center;
            color: #ffffff;
            margin-bottom: 0;

            @media (min-width: 1200px) {
              text-align: left;
              font-family: $font-helvetica;
              margin-bottom: 0;
              font-size: 48px;
              line-height: 55px;
            }
          }

          h3 {
            font-family: $font-helvetica-neue;
            font-style: normal;
            font-weight: bold;
            font-size: 20px;
            line-height: 24px;
            text-align: center;
            max-width: 267.53px;
            margin: 0 auto;
            padding: 1.5rem 0 45px;
            color: #f8f6f2;

            @media (min-width: 1200px) {

              text-align: left;
              margin: 0;
              max-width: 100%;
              padding: 1rem 0 54px;
            }
          }

          p {
            font-family: $font-avenir;
            font-style: normal;
            font-weight: 300;
            font-size: 14px;
            text-align: left;
            padding: 0 12px 1.5rem;
            line-height: 20px;
            color: #ffffff;
            max-width: 562px;
            margin: auto;

            @media (min-width: 1200px) {
              text-align: left;

              font-weight: 500;
              font-size: 18px;
              line-height: 25px;
              margin: initial;

              padding: 0 0 5.5rem 0;
            }

            &.slogan {
              font-family: $font-helvetica-neue;
              font-style: normal;
              font-weight: bold;
              font-size: 25px;
              line-height: 100%;

              color: #ffffff;
              padding-bottom: 2rem;

              @media (min-width: 1200px) {
                padding-bottom: 40px;
              }
            }
          }
        }
      }

      .Img__Container {
        padding-top: 0.5rem;
        display: flex;
        justify-content: center;

        img {
          width: 170px;
          height: 340px;

          @media (min-width: 1200px) {
            width: 268.46px;
            height: auto;
            position: relative;
            bottom: 19px;
          }
        }
      }
    }

    &--Light {
      height: 131.14px;
      background-color: #f8f6f2;

      @media (min-width: 1200px) {
        height: 111.72px;
      }

      .Hero__Container {
        display: flex;
        justify-content: center;

        p {
          font-family: $font-helvetica-neue;
          font-style: normal;
          font-weight: bold;
          font-size: 20px;
          line-height: 24px;
          position: absolute;
          color: #000000;
          bottom: 32px;
          text-align: center;
          max-width: 325px;
        }
      }
    }
  }

  &__Container {
    margin: 0 auto;
    max-width: 1200px;
  }

  a {
    outline: none;
  }
}