.about-solution {
  &__container {

    max-width: 1440px;
    margin: 0 auto 80px;

    @media (min-width: 1200px) {
      margin: 0 auto 163px;

    }

    .about-solution-img {

      @media (min-width: 1200px) {
        margin-top: 110px;
        display: flex;
        justify-content: flex-end;
      }

      img {
        max-width: 100%;
        margin-bottom: 76px;
        padding: 0 1rem 1.5rem;

        @media (min-width: 1200px) {
          margin-bottom: 0;
        }
      }
    }

    .about-grid {
      display: grid;
      grid-gap: 19px;
      grid-template-columns: 147px auto;
      align-items: center;

      @media (min-width: 1200px) {
        grid-template-columns: 32% auto;
        grid-gap: 6%;
      }

      .buyable-circle {

        @media (min-width: 1200px) {}

        img {
          max-width: 100%;

          @media (min-width: 1200px) {}
        }
      }

      .buyable {
        @media (min-width: 1200px) {}

        img {
          max-width: 153px;
          height: auto;

          @media (min-width: 1200px) {
            max-width: 349.18px;
          }
        }

        h2 {
          padding: 20px 0 16px;
          font-family: $font-helvetica;
          font-style: normal;
          font-weight: bold;
          font-size: 16px;
          line-height: 20px;

          /* or 125% */

          color: #000000;


          @media (min-width: 1200px) {
            padding:2.1rem 1rem 2.1rem 0;
            font-size: 36px;
            line-height: 44px;




          }
        }

        p {
          font-family: $font-helvetica-neue;
          font-style: normal;
          font-weight: normal;
          font-size: 15px;
          line-height: 108%;
          padding-right: 2rem;
          color: #3A3A3A;
          max-width: 780px;
          opacity: 0.7;

          span {
            font-style: italic;
            font-weight:600;
          }

          @media (min-width: 1200px) {
            font-size: 35px;
            line-height: 108%;
            padding-right:4rem;
            color: #3A3A3A;

            opacity: 0.7;
          }
        }
      }
    }
  }
}