.Collection {
    background: #FFFFFF;
    padding-bottom: 8rem;

    &__Container {
        background: #FFFFFF;
        margin: 0 auto 84px;
        display: grid;
        grid-gap: 33px;
        max-width: 100%;
        justify-content: center;

        grid-template-columns: repeat(auto-fill, minmax(320px, 320px));
        max-width: 100%;

        @media (min-width:992px) {
            justify-content: flex-start;
        }

        @media (min-width:1440px) {

            max-width: 100%;
            justify-content: center;




        }

        .Link {
            text-decoration: none;
        }

        .Card {
            &__Container {
                max-width: 100%;
                width: 320px;
                height: 380px;
                padding: 18px 24px 20px;
                background: #FFFFFF;

                box-sizing: border-box;
                border: 2px solid rgba(230, 232, 234, 0.54);
                border-radius: 20px;
                margin: auto;
                cursor: pointer;

                @media (min-width: 1200px) {
                    &:hover {
                        border: none;
                        box-shadow: 0 15px 32px 0 rgba(0, 0, 0, .3);
                    }
                }

                .Logo {
                    &__Container {
                        height: 176px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        object-fit: contain;

                        img {
                            max-height: 100%;
                            height: auto;
                            padding: 10px 0;

                        }
                    }
                }

                .Text {
                    &__Container {
                        height: 168px;
                        display: flex;
                        flex-direction: column;
                        justify-content: space-between;

                        h5 {
                            font-family: $font-helvetica-neue;
                            font-style: normal;
                            font-weight: bold;
                            font-size: 24px;
                            line-height: 29px;

                            color: #000000;
                            padding-bottom: 5px;
                            border-bottom: 3px solid black;
                        }

                        hr {
                            height: 3px;
                            color: black;
                            background-color: black;
                        }

                        p {
                            &:first-of-type {
                                margin: 1.4rem 0 0;
                                font-family: $font-helvetica-neue;
                                font-style: normal;
                                font-weight: bold;
                                font-size: 14px;
                                line-height: 17px;

                                color: #000000;
                            }

                            &:nth-of-type(2) {
                                font-family: $font-helvetica;
                                font-style: normal;
                                font-weight: normal;
                                font-size: 14px;
                                line-height: 17px;
                                display: flex;
                                align-items: center;
                                margin-top: 4px;
                                color: #000000;


                            }
                        }

                        .Ratings {

                            img {
                                max-width: 30.73px;
                                height: 25px;
                                margin-bottom: 2px;
                                margin-right: 16.53px;
                                object-fit: contain;

                                &.TagBCorp {
                                    width: 25px;
                                    height: 24px;

                                }

                                &.TagBBB {
                                    height: 29.4px;
                                }
                            }

                        }
                    }
                }

                &--approved,
                &--verified {
                    position: relative;

                    .Verified {
                        position: absolute;
                        right: 10px;
                        top: 10px;
                    }

                    .Approved {
                        background-color: black;
                        border-top-left-radius: 20px;
                        border-bottom-right-radius: 12px;
                        top: 0;
                        position: absolute;
                        height: 24px;
                        min-width: 131px;
                        font-family: $font-helvetica-neue;
                        color: white;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        font-style: normal;
                        font-weight: bold;
                        font-size: 12px;
                        line-height: 15px;
                        left: 0;

                    }
                }
            }
        }
    }

    &__Filter {
        max-width: 1024px;
        margin: 0 auto 3.1rem;
        display: flex;
        justify-content: space-around;

      
        h2 {
            font-family: $font-helvetica;
            font-style: normal;
            font-weight: bold;
            font-size: 28px;
        


            color: #000000;

            @media (min-width: 1200px) {
                margin-bottom: 0;
                

            }
        }

        @media (max-width: 991px) {
            align-items: center;
            
        }

        @media (min-width: 992px) {
            flex-direction: row;
            justify-content: space-between;
            align-items: center;

            margin: 0 auto 2.1rem;
        }
    }
}