.about-info {

    @media (min-width: 1200px) {}

    &__container {

        @media (max-width: 436px) {
            margin: 0 auto 10rem;

        }

        margin: 0 auto 4rem;
        padding: 0 2rem 1rem;
        max-width: 1023px;

        @media (min-width: 1023px) {
            margin: 0 auto 169px;

            padding: 0 0 0 2rem;
        }

        @media (min-width: 1200px) {
            max-width: 1440px;
            padding: 0;
        }


        .main-grid {

            @media (min-width: 399px) {
                display: grid;
                padding: 0;
            }


            @media (min-width: 1023px) {

                grid-gap: 39px;
                grid-template-columns: 63% auto;
            }


            div {


                &.text {
                    &__container {
                        @media (min-width: 1200px) {
                            display: flex;
                            flex-direction: column;
                            align-items: flex-end;

                        }

                    }
                }

                @media (min-width: 1200px) {}

                h2 {
                    font-family: $font-inter;
                    font-style: normal;
                    font-weight: bold;
                    font-size: 28px;
                    line-height: 34px;
                    margin-bottom: 32px;
                    color: #000000;


                    @media (min-width: 1200px) {
                        width: 708px;
                        max-width: 712px;
                        font-family: $font-helvetica-neue;
                        margin-bottom: 4rem;
                        font-size: 36px;
                        line-height: 44px;


                    }
                }

                .items-grid {
                    display: grid;

                    justify-content: center;
                    grid-gap: 5%;
                    grid-template-columns: repeat(auto-fill, minmax(158px, 177px));

                    @media (min-width: 992px) {
                        max-width: 712px;
                        width: 100%;
                        grid-gap: 8%;

                        justify-content: flex-start;
                    }

                    .item {
                        @media (min-width: 1200px) {


                            &:nth-of-type(1) {
                                order: 1;
                            }

                            &:nth-of-type(2) {
                                order: 2;
                            }

                            &:nth-of-type(3) {
                                order: 4;
                            }

                            &:nth-of-type(4) {
                                order: 5;
                            }

                            &:nth-of-type(5) {
                                order: 3;
                            }

                        }

                        div {
                            min-height: 50px;
                            margin-bottom: 12px;

                            @media (min-width: 1200px) {
                                min-height: 72px;
                                margin-bottom: 24px;
                            }

                            img {
                                @media (min-width: 1200px) {}


                            }
                        }


                        h5 {
                            font-family: $font-helvetica;
                            margin-bottom: 12px;
                            font-style: normal;
                            font-weight: bold;
                            font-size: 15px;
                            line-height: 17px;

                            color: #AF0505;


                            @media (min-width: 1200px) {
                                margin: 0 0 15px;
                            }
                        }

                        p {
                            font-family: $font-helvetica-neue;
                            font-style: normal;
                            font-weight: normal;
                            font-size: 14px;
                            line-height: 17px;

                            color: #3A3A3A;

                            margin-bottom: 8px;

                            @media (min-width: 1200px) {
                                margin: 8px 0;
                            }

                        }
                    }
                }
            }

            .search {
                &__container {
                    @media (min-width: 1200px) {
                        display: flex;
                        justify-content: flex-end;
                    }
                }

            }

            img {
                &.search {
                    @media (min-width: 1023px) {
                        position: relative;
                        bottom: 6rem;
                        max-width: 100%;
                        object-fit: contain;
                        ;
                    }
                }

            }
        }
    }
}