.VerifiedBrands {
  &__Grid {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 47px;
    margin: auto;
    padding: 2rem 0;
    max-width: 1044px;
    @media (min-width: 992px) {
      grid-template-columns: 198px auto;
      padding: 3.2rem 2rem;
    }
    @media (min-width: 1440px) {
      max-width: 1440px;
    }
    .Accordion{
        &__Container{
            display:none !important;
            @media (min-width: 992px) {
            display: block !important;
            }
        }
       
      }
  }

}
.MuiPaper-elevation1 {
  box-shadow: 0px 0px 0px -1px rgba(0, 0, 0, 0.2),
    0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 1px 0px 0px rgba(0, 0, 0, 0.12) !important;
}
.MuiAccordion-root.Mui-expanded {
    margin: 0 !important;
}

.MuiCollapse-container{
   
    border-bottom:1px solid #D5D5D5;
   
}

