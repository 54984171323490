.Insights {
    padding: 2rem 1rem 6rem;
    margin:0 auto;
 
   background-color: white;
   
    max-width: 856px;
    @media (min-width: 1200px) {
        padding: 0 0 299px;
    }

    &__Row {
        display: flex;
        max-width: 1023px;
        margin: 0 auto;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 2.5rem;
    }

    &__Container {
       
      
       display: flex;
        flex-wrap: wrap;
        @media (min-width: 1200px) {
           
            gap: 31.99px;
    
            justify-content: flex-start;
            
        }

        .Insight {
            &__Container {
  
                cursor: pointer;
                box-shadow: 2px 2px 25px rgba(0, 0, 0, 0.1);
                border-radius: 20px 20px 0px 0px;
                max-width: 264px;

                @media (max-width: 1199px) {
                    box-shadow: 2px 2px 20px rgba(0, 0, 0, 0.1);
                    border-radius: 0px;
                    margin: auto;
                    max-width: 100%;
                    width: 326px;
                    margin-bottom: 36px;
                    height: 121.71px;
                }
                &:hover{
                    opacity: .9;
                }



                .Img {
                    &__Container {
                        @media (max-width: 1199px) {
                           display: grid;
                           grid-template-columns: 112.92px auto;
                        }
                        img {
                            border-radius: 20px 20px 0px 0px;
                            max-width: 286.4px;
                            object-fit: cover;
                            max-height: 232.05px;
                            width: 100%;

                            @media (max-width: 1199px) {
                                max-width:112.92px;
                               
                               
                                width: 100%;
                            }
                        }

                        .Img-post{
                            @media (max-width: 1199px) {
                              
                                border-radius:  0px;
                                height:121.71px;
                                
                            }
                        }
                    }
                }



                .Text {
                    &__Container {

                        padding: 17px 1.5rem 10px 19px;
                        @media (max-width: 1199px) {
                            display: flex;
                            padding:4% 1% 4% 5%;
                            flex-direction: column;
                            justify-content: space-between;
                        }
                        
                        p {

                            font-family: $font-helvetica-neue;
                            font-style: normal;
                            font-weight: bold;
                            font-size: 18px;
                            line-height: 22px;

                            color: #000000;
                            margin-bottom: 1rem;
                            @media (max-width: 1199px) {
                                
                                margin-bottom: 0px;
                                font-size:(14px);
                                line-height: 22px;
                                
                                overflow: hidden;
                                text-overflow: ellipsis;
                                display: -webkit-box;
                                -webkit-line-clamp: 2; 
                                        line-clamp: 2; 
                                -webkit-box-orient: vertical;
                                
                                
                            }
                            @media (max-width: 440px) {
                                
                              
                                font-size:getvwM(14px);
                                line-height: getvwM(22px);
                                
                        
                                
                                
                            }
                            &.Category {

                               
                                 display:flex;
                                 align-items: center;
                                 justify-content: space-between;
                                 span{
                                     &:first-of-type{
                                        text-transform: uppercase;

                                        font-style: normal;
                                        font-weight: bold;
                                        font-size: 12px;
                                       
                                   
                                        color: #919191;
                                         overflow-y: hidden;

                                         @media (max-width: 1199px) {
                                            margin-bottom: 5px;
                                            font-style: normal;
                                            font-weight: bold;
                                            font-size: 14px;
                                            line-height: 17px;
                                            
                                            color: #909090;
                                            
                                            
                                        }
                                     }
                                     &:nth-of-type(2){
                                        font-family: $font-helvetica-neue;
                                        font-style: normal;
                                        font-weight: normal;
                                        font-size: 12px;
                                        line-height: 14px;
                                        text-align: right;
                                        
                                        color: #919191;
                                        
                                        
                                     }
                                 }
                               
                            }
                        }


                        .Date{
                            &__Row{
                                display: flex;
                                justify-content: space-between;
                                align-items: center;
                                img{
                                    max-width: min-content;
                                    width: 100%;
                                }
                            }
                        }
                        span {
                            font-family: $font-helvetica-neue;
                            font-style: normal;
                            font-weight: normal;
                            font-size: 16px;
                            line-height: 19px;

                            color: #919191;

                            @media (max-width: 1199px) {


font-weight: 300;
font-size: 14px;
line-height: 17px;

color: #C4C4C4;


                            }

                            @media (max-width: 440px) {
                                
                              
                                font-size:getvwM(14px);
                                line-height: getvwM(17px);
                                
                        
                                
                                
                            }
                        }

                    }
                }

            }
        }


    }



    &__Title {

        h2 {
            font-family: $font-avenir;
            font-style: normal;
            font-weight: 800;
            font-size: 24px;
            line-height: 33px;
            text-transform: uppercase;
            color: #000000;
        }

        div {
            display: block;
            width: 35px;

            border-bottom: 3px solid black;
        }


    }
}