.Products {
  background-color: white;
  min-height: 20vh;

  &__Container {
    background-color: white;
    max-width: 1024px;
    margin: 0 auto;
    padding: 2rem 1.2rem 10rem;

    @media (min-width: 1200px) {
      padding: 4.6rem 0 10rem;
    }

    h3 {
      text-align: center;
      font-family: $font-helvetica-neue;
      font-style: normal;
      font-weight: bold;
      font-size: 22px;
      line-height: 27px;

      margin-bottom: 43px;
      color: #000000;
    }

    h2 {
      font-family: $font-helvetica-neue;
      font-style: normal;
      font-weight: bold;
      font-size: 22px;
      line-height: 27px;
      margin-bottom: 33px;

      color: #000000;

      @media (min-width: 992px) {
        text-align: left;
        font-family: $font-inter;
        font-style: normal;
        font-weight: bold;
        font-size: 28px;
        line-height: 34px;

        color: #000000;
        padding-bottom: 3rem;
      }
    }

    > div {
      display: grid;
      justify-content: center;

      @media (min-width: 992px) {
      }

      grid-gap: 32px;
      grid-template-columns: repeat(auto-fill, minmax(320px, 320px));

      @media (max-width: 991px) {
        grid-gap: 8px;
        grid-template-columns: repeat(auto-fill, minmax(160px, 160px));
      }

      &.ProductsGrid {
        &--beauty {
          @media (max-width: 991px) {
            grid-gap: 8px;
            grid-template-columns: repeat(auto-fill, minmax(160px, 160px));
          }

          grid-gap: 32px;
          grid-template-columns: repeat(auto-fill, minmax(320px, 320px));
        }

        &--medication {
          .Product {
            border: 1px solid #919191;
          }
        }
      }

      .Product {
        &__Container {
          h4 {
            font-family: $font-helvetica-neue;
            font-style: normal;
            font-weight: bold;
            color: #000000;
            font-size: 10px;
            line-height: 12px;
            margin-top: 8px;
            @media (min-width: 992px) {
        
              font-size: 16px;
              line-height: 20px;
            }
          }
          p {
            font-size: 10px;
line-height: 12px;
margin: 4px 0 1rem;
font-family: $font-helvetica-neue;
font-style: normal;
font-weight: 500;


color: #000000;
@media (min-width: 992px) {
  font-size: 16px;
  line-height: 19px;
}
          }
        }
        max-width: 100%;
        border-radius: 20px;
        width: 320px;
        height: 380px;
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        position: relative;
        cursor: pointer;

        border: 2px solid rgba(230, 232, 234, 0.54);


        @media (min-width: 992px) {
        &:hover {
          border: none;
          box-shadow: 0 15px 32px 0 rgba(0, 0, 0, .3);
      }
    }
        @media (max-width: 991px) {
          height: 190px;
          border-radius: 10px;
          width: 160px;
        }

        img {
          z-index: 2;
          object-fit: contain;
          max-width: 70%;

          margin: auto;
          opacity: 1;
          -webkit-transition: 0.3s ease-in-out;
          transition: 0.3s ease-in-out;

          @media (max-width: 991px) {
            max-height: 85%;
          }
        }

        div {
          background: #f8f6f2;

          width: 100%;
          opacity: 0.9;
          border-bottom-right-radius: 10px;
          border-bottom-left-radius: 10px;
          position: absolute;
          bottom: 0;
          z-index: 2;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          font-family: $font-helvetica-neue;
          font-style: normal;
          font-weight: normal;
          font-size: 10px;
          line-height: 12px;
          text-align: center;
          color: #000000;

          font-weight: 600;

          padding: 11px;
        }

        .Card {
          &__Container {
            background: #f8f8f0;
            border-radius: 10px;
            height: 229px;
            width: 237px;
            display: flex;

            &--medication {
              background: #ffffff;
              border: 1px solid #919191;
              box-sizing: border-box;
              border-radius: 10px;
            }
          }
        }
      }
    }
  }
}
