.about-consumer {
  &__container {
    max-width: 1440px;
    margin:auto;
    @media (min-width: 1200px) {
    }

    .grid {
      @media (min-width: 1200px) {
        display: grid;
        margin-bottom: 2rem;
        grid-gap: 68px;
        grid-template-columns: 1fr 1fr;
      }

      .images {
     
        @media (min-width: 1200px) {
          display: flex;
          flex-direction: column;
          position: relative;
        }

        img {
          &:nth-of-type(1) {
            max-width: 90%;

            @media (min-width: 1200px) {
              max-width: 100%;
            }
          }

          &:nth-of-type(2) {
            margin-bottom: 1.5rem;

            @media (min-width: 1200px) {
              max-width: 93.38px;
              position: absolute;
              bottom: -40px;
              right: 15px;
            }
          }
        }
      }

      .text {
        padding: 2rem 2rem 3.5rem;

        @media (min-width: 1200px) {
          max-width: 496.79px;
          padding: 0;
          display: flex;
          justify-content: center;
          flex-direction: column;
        }

        h2 {
          font-family: $font-inter;
          font-style: normal;
          font-weight: bold;
          font-size: 28px;
          line-height: 34px;
          margin-bottom: 1rem;

          color: #000000;

          @media (min-width: 1200px) {
            font-family: $font-helvetica-neue;
            margin-bottom: 2rem;
            font-size: 36px;
            line-height: 44px;
          }
        }

        p {
          font-family: $font-helvetica-neue;
          font-style: normal;
          font-weight: normal;
          font-size: 16px;
          line-height: 108%;

          color: #3a3a3a;

          opacity: 0.7;
          padding: 1rem 0;

          @media (min-width: 1200px) {
            font-family: $font-helvetica-neue;
            font-style: normal;
            font-weight: normal;
            font-size: 22px;
            line-height: 108%;
            max-width: 478px;
            color: #000000;
            padding: 0 0 1.5rem;
            opacity: 0.7;
          }
        }
      }
    }
  }
}
